<template>
  <br />
  <div class="smart-wrap" style="margin: 0 auto">
    <div class="row data show"  id="stepOne">
      <div class="input-wrap col-md-12">
        <label for="">Phone</label>
        <input type="text" v-model="data.phone"/> 
      </div>
      <ui-button id="setPayment" style="margin:20px 0 0 0;" class="col-md-12 ghost" :color="'green'" :text="'Continue'" v-on:click="contactOperations()"/>
    </div>
    <div  class="row data" id="stepTwoA">
      <p>{{data.firstName }} </p>&nbsp;
      <p> {{ data.lastName }} </p>
      <h6 class="col-12">Is it you?</h6>
      <ui-button class="col-md-6" :color="'green'" :text="'Yes'" v-on:click="setStep('three')"  />
      <ui-button class="col-md-6" :color="'red'" :text="'No'" v-on:click="setStep('one')" />
    </div>

    <div  class="row data" id="stepTwoB">
      <div class="input-wrap col-md-12">
        <label for="">Phone</label>
        <input type="text" v-model="data.phone" disabled /> 
      </div>
      <div class="input-wrap col-md-12">
        <label for="">Fist Name</label>
        <input type="text" v-model="data.firstName" />
      </div>
      <div class="input-wrap col-md-12">
        <label for="">Last Name</label>
        <input type="text" v-model="data.lastName" />
      </div>
      <ui-button  style="margin:20px 0 0 0;" class="col-md-12 ghost" :color="'green'" :text="'Continue'" v-on:click="setStep('three')"/>
    </div>
    <div class="row data" id="stepThree">
      <p>{{data.firstName }} </p>&nbsp;
      <p> {{ data.lastName }} </p>
      <div id="wrapper">
        <div id="card"></div>
        <ui-button class="col-md-12" :text="'Pay'" id="pay" v-on:click="payInit()" />
      </div>
    </div>
  </div>

</template>
<script>
let token = ''
import { smd_processPayment } from "../../graphql/mutations";
import { smd_contactOperations } from "../../graphql/queries";
//Components
import PaperDisplayer from './PaperDisplayer.vue'
import UiButton from "../ui/UiButton.vue";
//Tools
import Vuex from 'vuex'
import { v4 as uuidv4 } from "uuid";
import { ApiError, Client, Environment } from "square";
import API from "@aws-amplify/api";
import { object } from '@apimatic/schema';
export default {
  name: "web-site",
  components: {
    UiButton,
    PaperDisplayer,
  },
  data() {
    return {
      //Data
      cno: "",
      date: "",
      cvv: "",
      zip: "",
      exist: true ,
      //
      client: "",
      clientPay: "",
      //
      data:{
        PK:null,
        SK:null,
        phone:'',
        firstName:'',
        lastName:'',
      }
    };
  },
  props:{
    listProducts:{
      type: Array,  
      default: () => []
      
    },
    amountCart:{
      type: object,  
      default: {}
      
    },
  },
  created() {
    //+12012222222
  },
  methods: {
    setStep(step){
      console.log(step);
      switch (step) {
        case 'one':
          document.getElementById('stepOne').classList.add('show')
          document.getElementById('stepTwoA').classList.remove('show')
          document.getElementById('stepTwoB').classList.remove('show')
          document.getElementById('stepThree').classList.remove('show')
        break;
        case 'twoA':
          document.getElementById('stepOne').classList.remove('show')
          document.getElementById('stepTwoA').classList.add('show')
          document.getElementById('stepTwoB').classList.remove('show')
          document.getElementById('stepThree').classList.remove('show')
        break;
        case 'twoB':
          document.getElementById('stepOne').classList.remove('show')
          document.getElementById('stepTwoA').classList.remove('show')
          document.getElementById('stepTwoB').classList.add('show')
          document.getElementById('stepThree').classList.remove('show')
        break;
        case 'three':
          document.getElementById('stepOne').classList.remove('show')
          document.getElementById('stepTwoA').classList.remove('show')
          document.getElementById('stepTwoB').classList.remove('show')
          document.getElementById('stepThree').classList.add('show')
        break;
      }
    },
    payInit() {
      setTimeout(() => {
        console.log('Token');
        console.log(token);
        console.log(token.token);
        this.payment()
        
      }, 2000);
    },
    async payment() {
      console.log('payment');
      console.log(JSON.stringify(this.listProducts));
      try {
        let pushData = await API.graphql({
          query: smd_processPayment,
          variables: {
            orgPK: this.publicOrganization,
            user: JSON.stringify(this.data),
            cartItems: JSON.stringify(this.listProducts),
            totalCharge: JSON.stringify(this.amountCart) ,
            paymentParameters: JSON.stringify({token:token.token, save:false}),
          },
        });
        console.log(pushData);
      } catch (error) {
        console.log(error);
      }
    },
    async contactOperations() {
      console.clear()
      let parameters = {
        query:'phone',
        phone:this.data.phone.value,
      }
      console.log(JSON.stringify(parameters));
      let pulldata = ''
      try {
        pulldata = await API.graphql({
          query: smd_contactOperations,
          variables: {
            orgPK:this.publicOrganization,
            parameters: JSON.stringify(parameters)
          },
        });
        pulldata = pulldata.data.smd_contactOperations
      } catch (error) {
        console.log(error);
      }

      if (pulldata.smd_response.statusCode =='401') {
        console.log('if');
        //payMethod()
        this.setStep('twoB')
      }else{
        console.log('else');
        this.setStep('twoA')
        for (const key in JSON.parse(pulldata.data)) {
          console.log(key);
          if (key == 'PK' || key == 'SK') {
            this.data[key].value = JSON.parse(pulldata.data)[key]
          }
          if (key == 'contact.firstName' || key == 'contact.lastName') {
            this.data[key.replace('contact.','')].value = JSON.parse(pulldata.data)[key].value
          }
        }
      }
      JSON.parse(pulldata.data)
    },

    setDataName(data){
      console.log( data.length );
    }
  },
  computed: {
    ...Vuex.mapState(['publicOrganization'])
    },
};
</script>