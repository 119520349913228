import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Amplify from 'aws-amplify';
import aws_exports from './aws-exports';
import {Auth} from 'aws-amplify'

import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader';

Amplify.configure(aws_exports);
applyPolyfills().then(() => {
  defineCustomElements(window);
});
Amplify.configure({
  API: {
    graphql_headers: async () => {
      const session = await Auth.currentSession();
      return {
        Authorization: session.getIdToken().getJwtToken(),
      };
    },
  },
  Storage: {
      AWSS3: {
          bucket: 'smartdash', //REQUIRED -  Amazon S3 bucket name
          region: 'us-east-1', //OPTIONAL -  Amazon service region
      }
  }
});
createApp(App).use(store).use(router).mount('#app')
