import { input } from "aws-amplify";
import { setTimeout } from "core-js";
import Swal from "sweetalert2";

//DATA
var today = new Date(),
    year = today.getFullYear(),
    month = today.getMonth(),
    monthTag = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    day = today.getDate(),
    selectedDay,
    setDate;    

let dataCalendar = {}

//Format Data (date, json, string, number, etc)
export function formatData(format, data) {
    //data = Value for format
    //format = type format for data
    //console.log('>>> Format Data Tool <<< \n' + format + ' ' + data);
    let value = data
    switch (format) {
        case 'check':
            try {
                if (typeof data == "string") {
                    if (data.startsWith('{') || data.startsWith('[')) {
                        value = JSON.parse(data)
                    }
                }
            } catch (error) {
                //console.log(error);
            }
            break;
        case 'phone':
            try {
                if (value.startsWith('+1')) {
                    value = value.slice(0, 2) + '(' + value.slice(2, 5) + ')' + value.slice(5, 8) + '-' + value.slice(8)
                }
                if (value.startsWith('+502')) {
                    value = '(' + value.slice(0, 3) + ')' + value.slice(3)
                }
                if (value.startsWith('+52')) {
                    value = '(' + value.slice(0, 2) + ')' + value.slice(2)
                }
            } catch (error) {
                //console.log(error);
            }
            break;
        case 'date':
            try {
                let hour = true
                if (data.includes('T')) {
                    date = new Date(data);
                }else{
                    const [year, month, day] = data.split('-');
                    date = new Date(year, month - 1, day); // Month is zero-based in JavaScript Date constructor
                    const localDateString = date.toLocaleDateString();
                    return [localDateString]
                }
                function transformUTCToLocal(utcDateTime) {
                    let date = new Date(utcDateTime);
                    
                    // Check if the UTC date time string contains a time
                    if (isNaN(date.getTime())) {
                        // If the date string doesn't contain a time, create a new Date object using only the date
                        const dateParts = utcDateTime.split('-');
                        date = new Date(Date.UTC(dateParts[0], dateParts[1] - 1, dateParts[2]));
                    }
                    
                    // Get the local date string using the toLocaleDateString() method
                    const localDate = date.toLocaleDateString(undefined, {
                        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                        dateStyle: 'short'
                    });
                    
                    // Get the current date in the user's local time zone
                    const now = new Date();
                    const localNow = now.toLocaleDateString(undefined, {
                        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                        dateStyle: 'short'
                    });
                    
                    // Compare the local date of the input date time to the local date of the current time
                    const dateDiff = Math.floor((new Date(localNow) - new Date(localDate)) / (1000 * 60 * 60 * 24));
                    
                    // Update the returned string with "Today" or "Yesterday" if appropriate
                    let result = localDate.toLocaleString();
                    if (dateDiff === 0) {
                        result = 'Today';
                    } else if (dateDiff === 1) {
                        result = 'Yesterday';
                    }
                    
                    // Return the local date with "Today" or "Yesterday" if appropriate
                    return result;
                }
                function getShortDateTimeString(date) {
                    // Convert the date to the local timezone
                    const localDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
                    
                    // Format the date in the local timezone
                    const options = {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: false
                    };
                    return localDate.toLocaleDateString(undefined, options);
                }
                value = [transformUTCToLocal(date)]
                value = getShortDateTimeString(date).split(',')
                console.log(value);
            } catch (error) {
                console.log(data);
                value = [data]
                console.log(error);
            }
            try {     
                const fechaLondresEnUTC = new Date(data);
                let fechaLocal = fechaLondresEnUTC.toLocaleString(undefined, { timeZoneName: 'short' });
                //value = [data]
                value = fechaLocal.split(',')
            } catch (error) {
                value = [data]
            }
            let date = ''
            break;
        case 'JSON':
            try {
                value = JSON.parse(data)
            } catch (error) {
                value = [data]
            }
            break;
        case 'currency':
            try {
                value = data.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
            } catch (error) {
                value = '*' + data
            }
            break;
        case 'type':
            value = typeof value
            break;
        case 'lower':
            try {
                value = value.toLowerCase()
            } catch (error) {
                value = value
            }
            break;
        case 'upper':
            try {
                value = value.toUpperCase();
            } catch (error) {
                value = value
            }
            break;
        case 'unescape':
            try {
                value = unescape(value);
            } catch (error) {
                value = value
            }
            break;
        case 'text':
            value = value
        break;
        case 'html':
            setTimeout(() => {
                document.getElementById( data[1]).innerHTML = data[0]
            }, 50);
        break;
        case 'string':
            value 
        break;
        default:
            //console.log('Format not fount: ' + format +'\n '+data);
            value = value
        break;
    }
    return value
}
export function goodFormat(data, format) {
    let vdata = ''
    try {
        switch (format) {
            case 'string':
                vdata = JSON.stringify(data, null, 4)
                break;
            case 'json':
                vdata = JSON.parse(JSON.stringify(data))
                break;
        }
        return vdata

    } catch (error) {
        console.log('-->Good Format Error: ' + format + '\n', data);
    }
}
export function processBinds(action, data) {
    let value = data
    //console.log('Data: ',data);
    for (const key in data) {
        if (typeof data[key] != 'object') {
            delete data[key]
            continue
        }
        try {
            let atttype = ''
            let attValue = ''
            if (data[key].hasOwnProperty('type')) {
                atttype = data[key].type
            } else {
                atttype = data[key].attributeType
            }
            if (!data[key].hasOwnProperty('attributeValue')) {
                attValue = 'value'
            } else {
                attValue = 'attributeValue'
            }
            switch (action) {
                case 'pull':
                    if (atttype == 'L') {
                        value[key].attributeValue = JSON.parse(value[key][attValue])
                    }
                    if (attValue == 'value') {
                        value[key].value = value[key].attributeValue
                    }
                    break;
                case 'push':
                    console.log('============================= \n');
                    console.log('Value of "' + value[key].attributeName + '" in ' + attValue + ' - ' + atttype, value[key]);
                    //console.log(value[key]) ;
                    if (atttype == 'L') {
                        value[key].attributeValue = JSON.stringify(value[key][attValue])
                        //console.log(value[key].attributeValue);
                    }
                    if (attValue == 'value') {
                        if (value[key].attributeValue != '') {
                            value[key].value = value[key].attributeValue
                        } else {
                            value[key].attributeValue = value[key].value
                        }
                    }
                    break;
            }
        } catch (err) {
            console.log('Err: ', err);
            console.log('Value of "' + value[key].attributeName, value[key]);
        }
    }
    console.log('Value: ', value);
    return value
}
export function number(action, data) {
    switch (action) {
        case 2:
            if (data.length < 2) {
                data = '0'+data 
            }
        break;
    }
    return data 
}
export function decimalAdjust(type, value, exp) {
    // Si el exp no está definido o es cero...

    value = +value;
    exp = +exp;
    // Si el valor no es un número o el exp no es un entero...
    if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
    return NaN;
    }
    // Shift
    value = value.toString().split('e');
    value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
    // Shift back
    value = value.toString().split('e');
    return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
}
export function stringAdd1(data) {
    let text = data;  // Your string

    // Extract the numerical part from the string using a regular expression
    let numberPart = text.match(/\d+/)[0];

    // Convert the extracted number to a number, add 1, and convert it back to a string
    let result = text.replace(numberPart, (parseInt(numberPart) + 1).toString());
    return result
}

//Process Data
export function processLayout(layout, action, data) {
    let order= 1
    for (let index = 0; index < layout.length; index++) {
        let element = layout[index];
        if (element.edit == false) {
            layout[index].readOnly = true
        }
        
        switch (action) {
            case 'setOrder':
                if (data) {
                    if (data.write == false || element.edit == false) {
                        layout[index].readOnly = true
                    }
                }
                if ((element.metaType == "metaInput" || element.metaType == "metaLayout") && element.smartInput && element.smartInput == true) {
                    layout[index].order = order
                    order++
                }
            break;
            case 'checkResponsible':    
                if (layout[index].usersCanRead.includes('@responsible') || layout[index].read == true  ) { //usersCanRead - usersCanEdit
                    layout[index].read = true
                    if (layout[index].usersCanEdit.includes('@responsible')) {
                        layout[index].edit = true
                        layout[index].readOnly = false
                    }
                }
            break;
            case 'randomTexture':     
                if (layout[index].attributeName == 'bg-texture' ) { //usersCanRead - usersCanEdit
                    layout[index].url = data.url
                }
            break;
            case 'viewTexture':     
                if (layout[index].attributeName == 'bg-texture' ) { //usersCanRead - usersCanEdit
                    console.log(layout[index]);
                    console.log(data);
                    layout[index].styleComponent = layout[index].styleComponent.replace('opacity:0;','')
                    layout[index].styleComponent = layout[index].styleComponent.replace('opacity:1;','')
                    if (data == true) {
                        layout[index].styleComponent += 'opacity:0;'
                    }else{
                        layout[index].styleComponent += 'opacity:1;'
                    }
                }
            break;
            case 'randomLogo':     
                if (layout[index].attributeName == 'logo' ) { //usersCanRead - usersCanEdit
                    layout[index].url = data.url
                }
            break;
            case 'randomImage':     
                if (layout[index].attributeName == 'img1' ) { //usersCanRead - usersCanEdit
                    layout[index].url = data.url
                }
            break;
            case 'noneImg':     
                if (layout[index].attributeName == 'img1' ) { //usersCanRead - usersCanEdit
                    layout[index].styleWrap += 'display:none;'
                    console.log(document.getElementById(layout[index].id));
                }
            break;
        }
        if (element.smartComponents.length != 0) {
            processLayout(element.smartComponents, action, data)
        }
    }
    return layout
}
export function recordToDisplay(data) {
    let value = data
    for (const key in data) {
        let tmpValue = ''
        if (typeof data[key] == 'object' && key != 'entity' && key != 'form' && key != 'parent' && key != 'layout' && key != 'publishImg' && key != 'images' && key != 'pictures') {
            
            for (const att in data[key]) {
                if (att != 'PK' && att != 'SK' ) {
                    tmpValue = tmpValue + ' ' + data[key][att]
                }
            }
            value[key] = tmpValue
        }
    }
    return value
}
export function processRecordsToDisplay(data) {
    let value = data
        for (let index = 0; index < data.length; index++) {
            const record = data[index];
            value[index] = recordToDisplay(record)
        }
    return value
}
export function processStyle(component, type, src, responsive, render) {
    
    let data = ''
    if (!src) {
        src = {
            fonts :[],
            palette :[]
        }
    }
    try {
        if (location.pathname == '/makerpreview'  ) {
            switch (document.getElementById('container-web').offsetWidth ) {
                case 992:
                    responsive = 1
                break;
                case 767:
                    responsive = 2
                break;
                case 450:
                    responsive = 3
                break;
                default :
                    responsive = 0
                break;
            }
        }
        for (const key in component.style) {
            switch (key) {
                case 'width': 
                    if (type == 'wrap') {
                        data += 'width:'+ component.style.width[responsive]+';'
                    }
                break;
                case 'widthC': 
                    if (type == 'component') {
                        data += 'width:'+ component.style.widthC[responsive]+';'
                    }
                break;
                case 'height': 
                    if (type == 'wrap') {
                        data += 'height:'+ component.style.height[responsive]+';'
                    }
                break;
                case 'heightC': 
                    if (type == 'component') {
                        data += 'height:'+ component.style.heightC[responsive]+';'
                    }
                break;
                case 'font': 
                    if (type == 'component') {
                        data += 'font-family: '+ ( src.fonts[component.style.font.font] ? 'var(--font'+component.style.font.font+')':'' ) +';'
                        data += 'color: '+ (src.palette[component.style.font.color] ? 'var(--color'+component.style.font.color+')':'' ) +';'
                        data += 'font-weight: '+ component.style.font.weight+';'
                        data += 'font-size:'+ (component.style.font.size[responsive] ? component.style.font.size[responsive]:'' ) +';'
                    }
                    break;
                    case 'bgc': 
                    if (component.style.bgc != undefined) {
                        data += 'background: '+ (src.palette[component.style.bgc] ? 'var(--color'+component.style.bgc+')':'' )+';'
                    }
                break;
                case 'margin': 
                data += 'margin:'+ component.style.margin +';'
                break;
                case 'padding': 
                data += 'padding:'+ component.style.padding +';'
                break;
            }
        }
        
        if (type == 'wrap') {
            data += ' '+ component.styleWrap
        } else {
            data += ' '+' margin:0; padding:0; '+ component.styleComponent
        }
        if (render) {
            data = replaceCssVars(data, src)
        }
        if (component.attributeName == 'TopMenu') {
            //console.log(component.attributeName,responsive, data);
        }
    } catch (error) { 
        console.log(error);
    }
    //console.log(data);
    return data
}
export function replaceCssVars(data, src) {
    const replacements = {
        'var(--font0)': src.fonts[0],
        'var(--font1)': src.fonts[1],
        'var(--font2)': src.fonts[2],
        'var(--font3)': src.fonts[3],
    };
    let css = data.replace(/var\(--(font)\d\)/g, match => replacements[match]);
    css = css.replace(/var\(--color(\d{1,2})\)/g, function(match, p1) {
    let reemplazo = src.palette[parseInt(p1)];
        return reemplazo || match; // Retorna la cadena original si el índice está fuera de rango
    });
    //console.log(css);
    return css
}
export function orderColors(data) {
    function getLuminance(color) {
        // Obtenemos los componentes RGB del color
        const r = parseInt(color.substr(1, 2), 16);
        const g = parseInt(color.substr(3, 2), 16);
        const b = parseInt(color.substr(5, 2), 16);
    
        // Aplicamos una fórmula para calcular la luminosidad
        return (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    }
    
    // Ordenamos los colores de oscuro a claro basándonos en su luminosidad

    let colors = data.filter((value) => value !== null && value !== undefined);
    const sortedColors = colors.sort((colorA, colorB) => {
        const luminanceA = getLuminance(colorA);
        const luminanceB = getLuminance(colorB);
        return luminanceA - luminanceB;
    });
    return sortedColors
}
//checkFilters
export function checkFilters(recod, filters) {
    let filter = false

    return filter
}
//Chek date by att
export function checkDateFilter(date, filter) {
    date = new Date(date);
    const today = new Date(); // Fecha actual
    const yesterday = new Date(today); // Fecha de ayer
    yesterday.setDate(yesterday.getDate() - 1);
    // Establecer la fecha actual al inicio del día
    today.setHours(0, 0, 0, 0);

    switch (filter) {
    case 'Today':
        date = new Date(date).toGMTString();
        return  today.toGMTString().includes(date.replace('00:00:00 GMT',''))
    case 'Yesterday':
        return date.toDateString() === yesterday.toDateString();
    case 'Last 7 Days':
        const timeDiff = date.getTime() - today.getTime();
        const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
        return daysDiff >= -6;
    case 'this week':
        return (
        date.getFullYear() === today.getFullYear() &&
        date.getMonth() === today.getMonth() &&
        date.getDate() >= today.getDate() - today.getDay()
        );
    case 'This Month':
        return (
        date.getFullYear() === today.getFullYear() &&
        date.getMonth() === today.getMonth()
        );
    case 'This Year':
        return date.getFullYear() === today.getFullYear();
    default:
        return false;
    }
}
//Front Functionalities 

//Responsive
export function responsiveCheck(params) {
    let value = 'desk'
    if (window.innerWidth < 992) { value = 'laptop' }
    if (window.innerWidth < 767) { value = 'tablet' }
    if (window.innerWidth < 520) { value = 'phone' }
    return value
}
export function orderArray(data, action) {
    switch (action.type) {
        case 'date':
            return data = (action.order == true) ? data.sort((a, b) => new Date(a[action.att]) - new Date(b[action.att])) : data.sort((a, b) => new Date(b[action.att]) - new Date(a[action.att])); 
        break;
        case 'text':
            return data = (action.order == true) ? data.sort((a, b) => a[action.att].localeCompare(b[action.att])) : data.sort((a, b) => b[action.att].localeCompare(a[action.att])); 
        break;
    }
    return data;
}
//General
export function loading(action, origin) {
    if (action == 'show') {
        document.getElementById("loading").classList.add('show')
        document.getElementById("body").classList.add("noScroll")
    } else {
        document.getElementById("loading").classList.remove('show')
        document.getElementById("body").classList.remove("noScroll")
    }
}
export function popUp(data, source, type) {
    if (type == 'err') {
        loading('hidde', 'getItem')
        switch (source) {
            case 'cach':
                if (typeof data == 'object') {
                    if (Object.entries(data).length === 0) {
                        data = data
                    } else {
                        data = JSON.stringify(data, null, 2);
                    }
                }
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: data,
                })
            break;
            case 'response':
            Swal.fire({
                icon: 'error',
                html: '<h4>'+data.userMessage+'</h4><strong>Code: '+data.statusCode+"</strong> <br><br>"+data.message
            }) 
            
            break;
            default:
                
            break;
        }
    }
    if (type == 'scc') {
        switch (source) {
            case 'response':
                Swal.fire({
                    icon: 'success',
                    title: 'Successful',
                    text: data.userMessage,
                    footer: data.message
                }) 
            break;
            default:
    
            break;
        }
    }
    if (type == 'warning') {
        switch (source) {
            case 'response':
                let message = '<h4>'+data.userMessage+'</h4><strong>Code: '+data.statusCode+"</strong> <br><br>"+data.message
                Swal.fire({
                    icon: 'warning',
                    html: message
                }) 
            break;
            default:
    
            break;
        }
    }
    /*
    let alert = {}

    switch (response.statusCode) {
        case '200': alert.icon = 'success'; alert.title = 'Success'; break;
        default: alert.icon = 'warning'; alert.title = 'Ops...'; break;
    }
    console.log(alert);
    if (prod == true) {
        Swal.fire({
            icon: alert.icon,
            title: alert.title,
            text: response.userMessage,
        })
    } else {
        Swal.fire({
            icon: alert.icon,
            title: response.userMessage,
            html: 'Status Code <b>' + response.statusCode + '</b>,<br> ' + response.message,
            footer: response.stack
        })
    }*/
}
export function actionSidebar() {
    //Side Bar control
    let sidebar = document.getElementById('sidebar')
    document.getElementById("searchSideBar").focus();
    if (sidebar.classList.value.includes('show')) {
        document.getElementById('sidebar').classList.remove('show')
        document.getElementById('btn-sidebar').classList.replace('bx-menu-alt-right', 'bx-menu')
        
    } else {
        document.getElementById('sidebar').classList.add('show')
        document.getElementById('btn-sidebar').classList.replace('bx-menu', 'bx-menu-alt-right')
    }
}
export function checkInput(layout, binds) {
    let valid = true
    function check(data) {
        for (let index = 0; index < data.length; index++) {
            const element = data[index];
            if (element.metaType == 'metaInput' && element.required == true) {
                if (binds[element.attributeName].attributeValue == '') {
                    document.getElementById(element.id).classList.add('required')
                    document.getElementById(element.id).title = 'Is Required'
                    $('#'+element.id).tooltip('show')
                    setTimeout(() => {
                        $('#'+element.id).tooltip('disable')
                        document.getElementById(element.id).title = ''
                        console.log( document.getElementById(element.id) );
                    }, 5000);
                    valid = false
                }
                else{
                    document.getElementById(element.id).classList.remove('required')
                }
            }
            if (element.smartComponents.length != 0) {
                check(element.smartComponents);
            }
        }
    }
    check(layout);
    try {
    } catch (err) {
        console.log(err);
    }
    return valid
}
export function calendar() {
    try {
        var today = new Date(),
            year = today.getFullYear(),
            month = today.getMonth(),
            monthTag = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
            day = today.getDate(),
            days = document.querySelectorAll('#days'),
            selectedDay,
            setDate,
            daysLen = days.length;
        // options should like '2014-01-01'
        function Calendar(selector, options) {
            this.options = options;
            this.draw();
        }
    
        Calendar.prototype.draw = function () {
            //this.getCookie('selected_day');
            this.getOptions();
            this.drawDays();
            var that = this,
                reset = document.getElementById('reset'),
                pre = document.getElementsByClassName('pre-button'),
                next = document.getElementsByClassName('next-button');
    
            //pre[0].addEventListener('click', function () { that.preMonth(); });
            //next[0].addEventListener('click', function () { that.nextMonth(); });
            //reset.addEventListener('click', function () { that.reset(); });
            while (daysLen--) {
                days[daysLen].addEventListener('click', function () { that.clickDay(this); });
            }
        };
    
        Calendar.prototype.drawHeader = function (e) {
            var headDay = document.getElementsByClassName('head-day'),
                headMonth = document.getElementsByClassName('head-month');
    
            e ? headDay[0].innerHTML = e : headDay[0].innerHTML = day;
            headMonth[0].innerHTML = monthTag[month] + " - " + year;
        };
    
        Calendar.prototype.drawDays = function () {
            var startDay = new Date(year, month, 1).getDay(),
                nDays = new Date(year, month + 1, 0).getDate(),
    
                n = startDay;
            for (var k = 0; k < 42; k++) {
                days[k].innerHTML = '';
                days[k].id = '';
                days[k].className = '';
            }
    
            for (var i = 1; i <= nDays; i++) {
                days[n].innerHTML = i;
                n++;
            }
    
            for (var j = 0; j < 42; j++) {
                if (days[j].innerHTML === "") {
    
                    days[j].id = "disabled";
    
                } else if (j === day + startDay - 1) {
                    setTimeout(() => {
                    }, 100);
                    if ((month === today.getMonth()) && (year === today.getFullYear())) {
                        this.drawHeader(day);
                        days[j].id = "today";
                    }
                }
                if (selectedDay) {
                    if ((j === selectedDay.getDate() + startDay - 1) && (month === selectedDay.getMonth()) && (year === selectedDay.getFullYear())) {
                        days[j].className = "selected";
                        this.drawHeader(selectedDay.getDate());
                    }
                }
            }
        };
    
        Calendar.prototype.clickDay = function (o) {
            var selected = document.getElementsByClassName("selected"),
                len = selected.length;
            if (len !== 0) {
                selected[0].className = "";
            }
            o.className = "selected";
            selectedDay = new Date(year, month, o.innerHTML);
            this.drawHeader(o.innerHTML);
            this.setCookie('selected_day', 1);
    
        };
    
        Calendar.prototype.preMonth = function () {
            if (month < 1) {
                month = 11;
                year = year - 1;
            } else {
                month = month - 1;
            }
            this.drawHeader(1);
            this.drawDays();
        };
    
        Calendar.prototype.nextMonth = function () {
            if (month >= 11) {
                month = 0;
                year = year + 1;
            } else {
                month = month + 1;
            }
            this.drawHeader(1);
            this.drawDays();
        };
    
        Calendar.prototype.getOptions = function () {
            if (this.options) {
                var sets = this.options.split('-');
                console.log(sets);
                setDate = new Date(sets[0], sets[1] - 1, sets[2]);
                day = setDate.getDate();
                year = setDate.getFullYear();
                month = setDate.getMonth();
            }
        };
    
        Calendar.prototype.reset = function () {
            month = today.getMonth();
            year = today.getFullYear();
            day = today.getDate();
            this.options = undefined;
            this.drawDays();
        };
    
        Calendar.prototype.setCookie = function (name, expiredays) {
            if (expiredays) {
                var date = new Date();
                date.setTime(date.getTime() + (expiredays * 24 * 60 * 60 * 1000));
                var expires = "; expires=" + date.toGMTString();
            } else {
                var expires = "";
            }
            document.cookie = name + "=" + selectedDay + expires + "; path=/";
        };
    
        Calendar.prototype.getCookie = function (name) {
            if (document.cookie.length) {
                var arrCookie = document.cookie.split(';'),
                    nameEQ = name + "=";
                for (var i = 0, cLen = arrCookie.length; i < cLen; i++) {
                    var c = arrCookie[i];
                    while (c.charAt(0) == ' ') {
                        c = c.substring(1, c.length);
    
                    }
                    if (c.indexOf(nameEQ) === 0) {
                        selectedDay = new Date(c.substring(nameEQ.length, c.length));
                    }
                }
            }
        };
        var calendar = new Calendar();
    } catch (error) {
        
    }
}
export function actionCalendar(action, id, data) {
    //Action is a action of calendar (init, next or pre mont and select day)
    //id is a referece div in draw calendar
    //data is a object that contain day, mont and year parameter
    function drawDays( data ) {
        setTimeout(() => {
            let days = document.querySelectorAll('#days-'+id)
            let daysLen = days.length;
            var startDay = new Date(data.year, data.month, 1).getDay(),
                nDays = new Date(data.year, data.month  + 1, 0).getDate(),
                n = startDay;
                
                console.log(startDay,nDays,days);

            for (var k = 0; k < 42; k++) {
                days[k].innerHTML = '';
                days[k].className = '';
            }
            // Rellena los elementos "days" con los días del mes.
            for (var i = 1; i <= nDays; i++) {
                console.log(i, n);
                days[n].innerHTML = i;
                days[n].title =data.year +'-'+number(2, JSON.stringify(data.month+1)) +'-'+number(2, JSON.stringify(i) );
                days[n].className = 'td-cal'
                if (days[n].title) {
                    if ( !data.availability.includes(new Date(days[n].title).getDay()) && id != 'table'  ) {
                        if (n == 0) {
                            days[n].className += ' disabled'
                        }else{
                            days[n-1].className += ' disabled'
                        }
                    }
                }
                n++;
            }

            for (var j = 0; j < 42; j++) {
                if ( (parseInt(days[j].innerHTML) < today.getDate()) && (data.month === today.getMonth()) && (data.year === today.getFullYear()) && id != 'table' ) {
                    days[j].className += " disabled";
                }
                if (days[j].innerHTML === "") {
                    days[j].className += " disabled";
                } else if (j === data.day + startDay - 1) {
                    if ((data.month === today.getMonth()) && (data.year === today.getFullYear()) && (j === today.getDate())) {
                        days[j].className += " today";
                    }
                }
                if (data.select) {
                    if ((j === data.select.getDate() + startDay ) && (data.month === data.select.getMonth()) && (data.year === data.select.getFullYear())) {
                        days[j].className += " selected";
                    }
                }
            }
        }, 100);
    }
    function drawHeader(data) {
        var headDay = document.getElementById('day-'+id),
        headMonth = document.getElementById('month-'+id);

        //today ? headDay.innerHTML = today : headDay.innerHTML = day;
        headMonth.innerHTML = monthTag[data.month] + " - " + data.year;
        
    }
    
    switch (action) {
        case 'init':
            setTimeout(() => {
                drawDays( data )
                drawHeader(data )
            }, 100);
            dataCalendar = data
        break;
        case 'next':
            if (dataCalendar.month >= 11) {
                dataCalendar.month = 0;
                dataCalendar.year = dataCalendar.year + 1;
            } else {
                dataCalendar.month = dataCalendar.month + 1;
            }
            console.log(dataCalendar);
            drawDays(dataCalendar);
            drawHeader(dataCalendar);
        break;
        case 'back':
            if (dataCalendar.month < 1) {
                dataCalendar.month = 11;
                dataCalendar.year = dataCalendar.year - 1;
            } else {
                dataCalendar.month = dataCalendar.month - 1;
            }
            drawDays(dataCalendar);
            drawHeader(dataCalendar);
        break;
        
    }
        
}
export function searchList(input, list){
    let value = document.getElementById(input).value
    let listItems = document.getElementById(list);
    for (let vindex = 0; vindex < listItems.children.length; vindex++) {
        const element = listItems.children[vindex];
        if ((element.innerText.toLowerCase()).includes(value.toLowerCase()) ) {
            document.getElementById(list).children[vindex].classList.remove('displayNone')
        }else{
            document.getElementById(list).children[vindex].classList.add('displayNone')
        }
    }
}
//User interact
export function response(data) {
    if (data.smd_response.statusCode) {

    }
}