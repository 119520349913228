
import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import createCache from 'vuex-cache';

//Tools
import { API, Storage, Auth } from 'aws-amplify'
import Swal from 'sweetalert2'
import { v4 as uuidv4 } from 'uuid';  

//GRAPQL
import { goodFormat, loading, popUp, orderColors } from "./tools";
import { smd_updateSmartRecord, smd_cloneSmartRecord } from '../graphql/mutations'
import { smd_getSMLItem, smd_getRecordObj, smd_getDashData, smd_listEntityObj, smd_getMyOrganizationByAlias, smd_listPAGWithData2 } from '../graphql/queries'
import { } from '../graphql/subscriptions'
import { DateUtils } from '@aws-amplify/core';

export default createStore({
   state: {
      //Version
      versionDash:'v1.5.8',
      urlHistory:'',
      //General
      gLanguage:'en',
      //Data Organization
      organizationID: "Not authorized",
      organizationData: "Not authorized",
      permissions: [],
      menu:null,
      organizationPalette:[], //Organization colors sorted from dark and light 

      // User
      user: "Not authorized",
      identityId: null,
      dataUser: "Not authorized",
      attUser: "Not authorized",
      userPermissions: [],

      //Data Record
      gRecord: null, //Record Global in focus 
      bRecord: null, //Record B Global in focus 
      record: null, //Record in focus
      recordSub: false, // This is a trgger when change record in other user
      recordView: null,//Record to Update
      targetFile: [],
      entities: [],
      products: [],
      allowPK: false,
      tmpCache:{},
      //Dashboard
      dashData: null,
      searchContact:'',

      //Preload Lists
      preloadLists: {},
      recordLists: {},

      //Data Component
      //Styles
      classes: [null],
      choice:'',

      //Data Module
      dataModule: null,
      moduleLayout: null,
      modules: null,
      menuModules: null,
      preloadList: false,
      pageForm: "pageForm",
      pageTable: "pageTable",
      pageName: "pageName",
      entity: "entity",
      pageQuery: "pageQuery",
      pageMeta: "Yes",
      pageModule: "#PAG",
      //Table
      tableRows: 20,
      responseActionTable: {},
      //Filters
      statusFilter: 'All', //Numer of rows
      dateFilter: 'none',
      rangeFilter: 'This Month',

      //TO - DO

      //Data Public
      publicOrganization: 'Not Authorized',
      publicOrganizationData: null,
      publicOrganizationDataObj:{},
      publicOrgPay: null,
      cartData: [],
      productParameters: { SK: '', parameters: {} },
      contactSK: '',
      currentItem: null,

      //Others
      calendarHours: { 
         '7:00':[], '7:30':[], '8:00':[], '8:30':[], '9:00':[], '9:30':[], '10:00':[], '10:30':[], '11:00':[], '11:30':[], '12:00':[],  '12:30':[], '13:00':[], '13:30':[], '14:00':[], '14:30':[], '15:00':[], '15:30':[], '16:00':[], '16:30':[], '17:00':[], '17:30':[], '18:00':[], '18:30':[], '19:00':[],
      },
      calAlert: false,
      quiz:{},
      notifications: [],
      validationString: {
         abc: /^[a-zA-Z0-9\_\-]{4,16}$/, // Letras, numeros, guion y guion_bajo
         nombre: /^[a-zA-ZÀ-ÿ\s]{1,40}$/, // Letras y espacios, pueden llevar acentos.
         password: /^.{4,12}$/, // 4 a 12 digitos.
         correo: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
         telefono: /^\d{7,14}$/ // 7 a 14 numeros.
      },
      dateToday: (new Date()).toISOString(),
      reload: false,
      phoneStore: [
         {

         }
      ],
      
      //Smart Maker
      webSite:null,
      customLayout: null,
      customItem: null,
      customWeb: null,
      clipBoard:{ },

      //Maker SRC
      elementList:[ 
         {
            attributeName:'metaModule',
            name:'Custom Module',
            label: 'metaModule', 
            metaType:'customModule',
         },
         // INPUTS
         {
            attributeName:'Input',
            name:'Input',
            label: 'Input', 
         },
         {
            attributeName:'Input',
            name:'Quote',
            label: 'Quote', 
         },
         {
            attributeName:'Input',
            name:'Calendar',
            label: 'calendar', 
         },
         {
            attributeName:'Tag',
            name:'Tag',
            label: 'Tag', 
            metaType:'Tag',
         },
         // TEXT ----
         {
            attributeName:'Title',
            name:'Title',
            text: 'Main Title',
         },
         {
            attributeName:'Text',
            name:'Text',
            text: 'Main Title',
         },
         // ENVELOPS
         {
            attributeName:'smartLayout',
            name:'Smart Layout',
            metaType:'metaLayout',
         },
         {
            attributeName:'Div',
            name:'Div'
         },
         {
            attributeName:'contactForm',
            name:'Contact Form'
         },
         {
            attributeName:'paymentMethod',
            name:'Payment Method'
         },
         {
            attributeName:'link',
            name:'Link'
         },
         {
            attributeName:'menu',
            name:'Menu'
         },
         //COMPONENTS
         {
            attributeName:'smartButton',
            name:'Button'
         },
         {attributeName:'img', name:'Img'},
         {attributeName:'iframe', name:'Iframe'},
         {attributeName:'video', name:'Video'},
         {attributeName:'shape', name:'Shape'},
         //Special Component
         {attributeName:'collapse', name:'Collapse'},
         {attributeName:'dropdown', name:'Dropdown'},
         {attributeName:'Div', name:'Container-in'},
         {attributeName:'Div', name:'Section'},
         {attributeName:'footer', name:'Footer'},
         {attributeName:'socialBubble', name:'Social bubble'},
         {attributeName:'copyRight', name:'Copy Right'},
         {attributeName:'slider', name:'Slider'},
         {attributeName:'gallery', name:'Gallery'},
      ],
      srcFonts:[
         "'Warpaint'",
         "'Gloss And Bloom'",
         "'Rolling'",
         "'LEMON MILK'",
         "'Imstory'",
         "'bunderan'",
         "'Raleway'",
         "'Montserrat'",
         "'Josefin Sans'",
         "'Indie Flower'",
         "'Patrick Hand'",
         "'Caveat'",
         "'Courgette'",
         "'Michroma'",
         "'Handlee'",
         "'Turret Road'",
         "'Righteous'",
         "'Love Ya Like A Sister'",
         "'Sail'",
         "'Yeon Sung'",
         "'Permanent Marker'",
         "'Gochi Hand'",
         "'Crafty Girls'",
         "'Bauhaus 93'",
      ],
      comboFonts:[
         {
            Title: "'Rock Salt'", 
            SubTitle: "'LEMON MILK' ", 
            Text: "'Montserrat'", 
            SubText: "'Montserrat'"
         },
         {
            Title: "'Warpaint'", 
            SubTitle: "'Patrick Hand'", 
            Text: "'Turret Road'", 
            SubText: "'Montserrat'"
         },
         {
            Title: "'Permanent Marker'", 
            SubTitle: "'Patrick Hand'", 
            Text: "'Raleway'", 
            SubText: "'Raleway'"
         },
         {
            Title: "'Michroma'", 
            SubTitle: "'Indie Flower'", 
            Text: "'Raleway'", 
            SubText: "'Raleway'"
         },
         {
            Title: "'Turret Road'", 
            SubTitle: "'Yeon Sung'", 
            Text: "'Patrick Hand'", 
            SubText: "'Patrick Hand'"
         },
         {
            Title: "'Righteous'", 
            SubTitle: "'Caveat'", 
            Text: "'Patrick Hand'", 
            SubText: "'Patrick Hand'"
         },
         {
            Title: "'Courgette'", 
            SubTitle: "'Handlee'", 
            Text: "'Raleway'", 
            SubText: "'Raleway'"
         },
         {
            Title: "'Love Ya Like A Sister'", 
            SubTitle: "'bunderan'", 
            Text: "'Josefin Sans'", 
            SubText: "'Josefin Sans'"
         },
         {
            Title: "'Imstory'", 
            SubTitle: "'Yeon Sung'", 
            Text: "'Turret Road'", 
            SubText: "'Turret Road'"
         },
      ]
   },
   mutations: {
      //Login
      setUp(state, data){
         
      },
      setUrlHistory(state, data){ state.urlHistory= data},
      //Data Organization
      SetOrganizationID(state, p_org) { console.log(' >>> Organization ID: ', p_org); state.organizationID = p_org; },
      SetOrganizationData(state, p_orgD) { console.log(' >>> Organization Data: ', p_orgD); state.organizationData = p_orgD; },
      SetPermissions(state, p_permissions) { state.permissions = p_permissions },
      setMenu(state, data) { state.menu = data },
      setGLanguage(state, data) { console.log(' >>> set  G Language: ', data); state.gLanguage = data },
      setOrganizationPalette(state, data) {  state.organizationPalette = data },

      //Data Public
      setPublicOrganization(state, data) { console.log(' --- Public Organization: ' + data); state.publicOrganization = data },
      setPublicOrganizationData(state, data) {
         state.publicOrganizationData = data 
      },
      setPublicOrganizationDataObj(state, data) {
         state.publicOrganizationDataObj = data 
      },
      setPublicOrgPay(state, data) {state.publicOrgPay = data },

      setCartData(state, data) { state.cartData = data },
      setProductParameters(state, data) { state.productParameters = data },

      setContactSK(state, data) { state.contactSK = data },

      //Data User
      SetUser(state, p_user) { state.user = p_user; },
      setIdentityId(state, data) { state.identityId = data; },
      setDataUser(state, data) { console.log(' >>> setDataUser: ', data); state.dataUser = data; },
      setAttUser(state, data) { console.log(' >>> setAttUser: ', data); state.attUser = data; },
      setUserPermissions(state, data) { state.userPermissions = data; },

      //Data Record
      setGetRecord(state, data) { console.log('>>> setGetRecord <<<\n' , data); state.getRecord = data },
      setRecord(state, data) { console.log('>>> SetRecord <<<\n' , data); state.record = data },
      setgRecord(state, data) { 
         console.log('>>> setgRecord <<<\n' , data); state.gRecord = null; state.gRecord = data;  
         if (data[1] == 'update' || data[1] == 'new') {
            //$('#modal-update').modal('show')
         }  
      },
      setbRecord(state, data) { 
         console.log('>>> setbRecord <<<\n' , data); 
         state.bRecord = null; 
         state.bRecord = data;  
         if (data[1] == 'update' || data[1] == 'new') {
            $('#modal-updateB').modal('show')
            //$('#modal-update').modal('toggle')
         }  
      },
      setRecordSub(state, data) {  state.recordSub = data },
      setRecordView(state, record) { console.log('>>> setRecordView <<<\n' + record.layoutName + ' <<<'); state.recordView = record },
      setTargetFiled(state, data) { console.log('>>> setTargetFiled <<<\n' , data); state.targetFile = data },

      //Filter Data
      setFilterData(state, data) { console.log(data[0] + ' - ' + data[1]); state[data[0]] = data[1] },
      setAllowSelectPK(state, p_nAllowPK) { state.allowPK = p_nAllowPK },
      //Data Dashboard
      setQuiz(state, data) { console.log(data); state.quiz = data; },
      setCalAlert(state, data) { console.log('setCalAlert: '+data); state.calAlert = data; },
      setDashData(state, data) { state.dashData = data; },

      //Preload Lists
      setEntities(state, data) { console.log('>>> setEntities', data); state.entities = data },
      setProducts(state, data) { /*console.log('>>> Products <<<\n ', data);*/ state.products = data;  },
      setPreloadLists(state, data) {
         try {
            state.preloadLists[data.name] = data.value
         } catch (error) {
            console.log(error);
         }
      },
      setRecordLists(state, data) { state.recordLists[data.name] = { list: data.value.list, headers: data.value.headers, headersV2: data.value.headersV2 };  },
      //SUBSCRIPTIONS
      updateRecordLists(state, data) {
         //console.log('>>> updateRecordLists <<<');

         //console.log(state.dataModule);
         let processedData = data
         let exist = false
         let filterPass = false
         let existStatus = false

         try {
            for (const key in data.attributes) {
               try {
                  if (data.attributes[key].attributeType == 'L') {
                     processedData[key] = JSON.stringify(data.attributes[key].attributeValue)
                  } else {
                     processedData[key] = data.attributes[key].attributeValue
                  }
                  if (key.includes('status')) {
                     existStatus = true
                     if (data.attributes[key].value == state.statusFilter || state.statusFilter == 'All' || state.statusFilter == '') {
                        filterPass = true

                     }
                  }
                  if (key == 'responsibles') {
                     processedData[key].forEach(resp => {
                        if (resp.id == state.user) {
                           console.log('>>>BINGO');
                           processedData.edit = true
                           processedData.read = true
                        }
                     });
                  }
                  if (key == 'responsible') {
                     if (processedData[key].id == state.user) {
                        console.log('>>>BINGO');
                        processedData.edit = true
                        processedData.read = true
                     }
                  }
               } catch (error) {
                  console.log(key, error);
               }
            }
            delete processedData.attributes
            if (state.dataModule.preload == true || existStatus == false) {
               filterPass = true
            }

            if (state.dataModule.allowSelectPK == true || state.dataModule.allowSelectPK == 'true') {
               processedData.edit = true
               processedData.read = true
            }
            if (processedData.edit == true) {
               processedData.read = true
            }
            //console.log('>>>DATA OF SUBSCRIPTION: ', processedData);
            //console.log('filterPass:' + filterPass);
            for (let index = 0; index < state.recordLists[data.shortEntity].list.length; index++) {
               const item = state.recordLists[data.shortEntity].list[index];
               if (item.SK == processedData.SK) {
                  //console.log('Item Encontrado: No. ' + index)
                  exist = true
                  processedData.subscribe = 'edit'

                  if (data.active == 0 || processedData.read == false || filterPass == false) {
                     state.recordLists[data.shortEntity].list.splice(index, 1)
                  } else {
                     state.recordLists[data.shortEntity].list.splice(index, 1)
                     setTimeout(() => {
                        state.recordLists[data.shortEntity].list.splice(0, 0, processedData);
                     }, 500);
                  }
               }
            }
            if (exist == false && processedData.read == true) {
               processedData.subscribe = 'new'
               if (filterPass == true) {
                  state.recordLists[data.shortEntity].list.splice(0, 0, processedData);
               }
            }
         } catch (error) {
            console.log(error);
         }
      },
      
      //Data Component
      setModule(state, data) { state.dataModule = data; },
      setModuleLayout(state, data) { state.moduleLayout = data; },
      setDataModules(state, data) {  state.modules = data; },
      setDataMenuModules(state, data) {  state.menuModules = data; },
      SetPageQuery(state, p_query) { state.pageQuery = p_query; },
      setSearchContact(state, data) { state.searchContact = data; },
      //Table
      SetTableRows(state, p_nRows) { state.tableRows = p_nRows },
      setResponseActionTable(state, data) { state.responseActionTable = data },
      
      //Components Styles
      setClasses(state, data) { state.classes = data },
      setChoice(state, data) { console.log('choice', data); state.choice = data },
      //Puclicc Items
      setCurrentItem(state, data) { console.log('currentItem: ' + data); state.currentItem = data },
      //Others
      setStatusReload(state, data) { console.log('>>>>>>>>>>>>>>>>>>>>RELOAD' + data); state.reload = data },
      //Notifications
      setNotifications(state, data) {
         switch (data.action) {
            case 'init':
               state.notifications = []
               for (let index = 0; index < state.recordLists.NOT.list.length; index++) {
                  try {
                     const element = state.recordLists.NOT.list[index];
                     let responsible = element.responsible.id
                     //console.log(responsible + ' - ' + state.user);
                     if (element.status == 'New' && responsible == state.user) {
                        state.notifications.push(element);
                     }
                  } catch (err) {
                     console.log(err, state.recordLists.NOT.list[index]);
                  }
               }
            break;
            case 'add':
               let pullData = data.value
               for (let index = 0; index < pullData.attributes.length; index++) {
                  let element = pullData.attributes[index]
                  pullData[element.attributeName] = element.attributeValue
               }
               let responsible = pullData.responsible.id
               console.log(responsible +' - '+state.user );
               if (pullData.status == 'New' && responsible == state.user) {
                  state.notifications.splice(0, 0, pullData)
               }
            break;
            case 'delete':
               for (let index = 0; index < state.notifications.length; index++) {
                  const element = state.notifications[index];
                  console.log(element);
                  if (element.SK == data.value.SK) {
                     state.notifications.splice(index, 1)
                  }
               }
            break;
         }
      },

      //SmartMaker function
      setWebSite(state, data){
         //console.clear()
         state.webSite = JSON.parse(JSON.stringify(data))
      },
      setCustomLayout(state, data){
         //console.clear()
         state.customLayout = JSON.parse(JSON.stringify(data))
      },
      setCustomItem(state, data){
         console.log('>>> Data <<<', data);
         console.log(!data.open);
         if (!data.open) {
            console.log($('#modal-custom'));
            $('#modal-custom').modal('show')
         }
         delete data.open
         state.customItem = JSON.parse(JSON.stringify(data, null, 2))
         event.stopPropagation()
      },
      setCustomWeb(state, data){
         if (
            data[0].elementType.toLowerCase()=='text' || data[0].elementType.toLowerCase()=='title' || data[0].elementType.toLowerCase()=='img' || data[0].elementType.toLowerCase()=='video'
            ){
            console.log('>>> Data <<<', data);
            state.customWeb =  JSON.parse(JSON.stringify(data, null, 2))
            $('#modal-custom').modal('show')
            $('#modal-custom').modal('toggle')
         }
         event.stopPropagation()
      },
      applyCustomItem(state, data){
         //console.clear()
         console.log(data);
         let layout = JSON.parse(JSON.stringify(state.customLayout))
         function custom(vdata) {
            for (let index = 0; index < vdata.length; index++) {
               if (vdata[index].id == data[0].id) {
                  try {
                     switch (data[1]) {
                        case 'edit':
                           vdata[index] = JSON.parse(JSON.stringify(data[0], null, 2))
                        break;
                        case 'delete':
                           vdata.splice(index, 1)
                        break;
                        case 'clone':
                           console.log(data[0]);
                           let clone = data[0]
                           function newID(element) {
                              console.log('Before ID: '+ element.id);
                              element.id = uuidv4()
                              console.log('After ID: '+ element.id);
                              if (element.smartComponents.length != 0) {
                                 for (let index = 0; index < element.smartComponents.length; index++) {
                                    newID(element.smartComponents[index])
                                 }
                              }
                           }
                           newID(clone)
                           vdata.splice(index+1, 0, clone);
                           return
                        break;
                     }
                  } catch (error) {
                     console.log(error);
                  }
               }else if(vdata[index].smartComponents.length != 0){
                  custom(vdata[index].smartComponents)
               }
            }
         }
         custom(layout);
         console.log(layout);
         state.customLayout = layout
      },
   },
   actions: {
      //Login
      async initSmartdash({commit, state, dispatch}, data){
         console.clear()
         
         let dataUser = {}
         Auth.signIn(data.user, data.pass)
         .then((user) => { 
            dataUser = {
               name: user.username,
               ...user.attributes
            }
            console.log(user);
            
            switch (data.path) {
               case 'none':
                  
               break;
               default:
   
               break;
            }
         })
         
         .catch((err) => {
            console.log(err);
         });
         const session = await Auth.currentUserCredentials();
         dataUser.accessKeyId = session.accessKeyId
         console.log(dataUser);
         console.log('Init Smartdash: ',data);
      },
      //Record
      async getRecord({ commit }, data) {
         console.log('>>>> GET RECORD FROM DASH CLIENT <<<<');
         console.log(data);
         let record = {}
         let config = {
            itemPK: data.PK,
            itemSK: data.SK,
            SMLPK: data.form.PK,
            SMLSK: data.form.SK,
            parent: data.parent,
            entity: data.entity
         }
         console.log(config);
         try {
            let pullData = []
            pullData = await API.graphql({
               query: smd_getSMLItem,
               variables: config,
            });
            pullData = pullData.data.smd_getSMLItem
            console.log(pullData);
            //Set layout of record
            record.layout = JSON.parse(pullData.data)
            record.layoutName = pullData.layoutName
            record.ACLPermits = pullData.ACLPermits
            //Set item Detail of record
            record.binds = JSON.parse(pullData.item)
            //console.log(record);
            //Object.assign(record.binds,  record.binds.attributes);
            //delete record.binds.attributes;
            console.log("--> Layout:\n", record.layout);
            console.log("-->Binds:\n ", record.binds);
            console.log('PEPE estuvo aqui')
            commit('setRecordView', record)
         } catch (error) {
            console.log(error);
            Swal.fire({
               icon: 'error',
               title: 'Something went wrong!',
               text: JSON.stringify(error),
               footer: 'getRecord'
            })
         }
      },
      async updateRecord({ commit }, data) {
         try {
            console.log(data);
            let pushData = ''
            pushData = await API.graphql({
               query: smd_updateSmartRecord, //smd_updateSmartRecord
               variables: {
                  input: {
                     shortEntity: data.shortEntity,
                     PK: data.PK,
                     SK: data.SK,
                     attributesStr: JSON.stringify(data.attributes),
                     entity: data.entity,
                     form: data.form,
                     parent: data.parent,
                     usersCanEdit: data.usersCanEdit,
                     usersCanRead: data.usersCanRead,
                     groupsCanEdit: data.groupsCanEdit,
                     groupsCanRead: data.groupsCanRead,
                  }
               },
            });
            console.log(pushData);
            /*Swal.fire({
               icon: 'success',
               title: 'Success',
               text: 'Record updated successfully',Nice
            })*/
         } catch (error) {
            console.log(error);
            Swal.fire({
               icon: 'error',
               title: 'Something went wrong!\nupdateRecord',
               text: JSON.stringify(error),
            })
         }
         //commit('setRecord', data  )
      },
      async cloneRecod({ commit }, data) {
         loading('show','cloneRecod')
         console.log(data);
         let pushData = ''
         try {
            pushData = await API.graphql({
               query: smd_cloneSmartRecord,
               variables: { 
               PK: data.PK,
               SK: data.SK
               },
            }); 
            pushData = pushData.data.smd_cloneSmartRecord
            Swal.fire({
               icon: 'success',
               title: 'Successfully cloned' ,
               //text: pushData.smd_response.statusCode
            })
         } catch (error) {
            Swal.fire({
               icon: 'error',
               title: 'Something went wrong!' ,
               text: error
            })
         }
      loading('hidde','cloneRecod')
      },
      //ORGANIZATION DATA
      async getPublicOrganization({ commit, state }) {
         try {
            let pullData = await API.graphql({
               query: smd_getMyOrganizationByAlias,
               variables: {
                  alias: window.location.host.toLowerCase(),
                  //Whit www. complete aliass
               },
            });
            pullData = pullData.data.smd_getMyOrganizationByAlias
            commit('setPublicOrganization', JSON.parse(pullData.data).SK.replace('#ORG', 'ORG'))
            let data = {}
            let att = JSON.parse(pullData.data).attributes
            commit('setPublicOrganizationDataObj', att)
            for (const key in att) {
               try {
                  if (att[key].attributeType == 'L') {
                     data[key] = JSON.parse(att[key].attributeValue)
                  } else {
                     data[key] = att[key].attributeValue
                  }
               } catch (error) {
                  console.log(' ***' + ket + ': ', error);
               }
            }
            if (data.rootElement && data.rootElement != '' && window.location.pathname == "/") {
               window.open(window.location.href+data.rootElement,"_self");
            }
            if (state.user == 'smd_webuser') {
               document.head.insertAdjacentHTML("beforeend", data.globalHeaderScripts)
            }
            console.log(orderColors(data.colorSeriesA));
            commit('setPublicOrganizationData', data)
            commit('setPublicOrgPay', data)
            commit('SetOrganizationData', data)
            commit('setOrganizationPalette', orderColors(data.colorSeriesA))
            document.getElementById('favicon').href = data.favicon[0].url
            document.getElementById('title').innerText = data['organization.name']
         } catch (error) {
            console.log(error);
            Swal.fire({
               icon: 'error',
               title: 'Something went wrong!',
               text: JSON.stringify(error),
               footer: 'getPublicOrganization'
            })
         }
         return true
      },
      //LIST PRELOAD
      async getPreloadList({ commit, state }, record) {
         let element = record.metaJSON
         let data = { name: '', value: [] }
         let ORG = '#SMARTDASH'
         //console.log(element);
         if (element.entityType == 'ORGANIZATION' || element.entityType == 'ORG') {
            ORG = state.organizationID
         }
         if (state.dataModule.allowSelectPK != true) {
            ORG = state.organizationID
         }
         if (element.entityType == 'ITEMSK') {
            ORG = record.SK
         }
         if (element.entityType == 'SYSTEM') {
            ORG = '#SMARTDASH'
         }
         if (element.shortEntity == '#SML' &&  (state.userPermissions.includes('Superadmin') || state.userPermissions.includes('Admin'))) {
            let orgList = []
            let type = 'form'
            if (element.INDEX3) {
               type = element.INDEX3
            }
            console.log(element);
            orgList.push({PK:'#SMARTDASH',SK:'#SMARTDASH', value:'#SMARTDASH', id:'#SMARTDASH'})

            for (let index = 0; index < orgList.length; index++) {
               const organization = orgList[index];
               try {
                  let pullData = await API.graphql({
                     query: smd_listEntityObj,
                     variables: {
                        active: '1',
                        PK: organization.value,
                        shortEntity: element.shortEntity,
                        pageSize: 0,
                        index: 3,
                        group: type,
                     }
                  })
                  pullData = pullData.data.smd_listEntityObj
                  let items = JSON.parse(pullData.data)
                  for (let index = 0; index < items.length; index++) {
                     const item = items[index];
                     try {
                        let push = {
                           PK: item.PK,
                           SK: item.SK,
                           value: item.attributes[element.fieldValue].attributeValue,
                        }
                        if (item.attributes[element.fieldID]) {
                           push.id = item.attributes[element.fieldID].attributeValue
                        } else {
                           if (item[element.fieldID]) {
                              push.id = item[element.fieldID]
                           } else {
                              push.id = 'not found'
                           }
                        }
                        data.value.push(push)
                     } catch (error) {
                        console.log(error);
                     }
                  }
                  data.name = element.shortEntity
                  //console.log(data);
                  
               } catch (error) {
                  console.log('Element:', element, error);
               }
            }
            commit('setPreloadLists', data)
         }else{
            //console.log(ORG);
            try {
               let pullData = await API.graphql({
                  query: smd_listEntityObj,
                  variables: {
                     active: '1',
                     PK: ORG,
                     shortEntity: element.shortEntity,
                     pageSize: 0,
                  }
               })
               pullData = pullData.data.smd_listEntityObj
               let items = JSON.parse(pullData.data)
               for (let index = 0; index < items.length; index++) {
                  const item = items[index];
                  console.log(item.attributes[element.fieldValue]);
                  try {
                     let push = {
                        PK: item.PK,
                        SK: item.SK,
                        value: item.attributes[element.fieldValue].attributeValue,
                     }
                     if (item.attributes[element.fieldID]) {
                        push.id = item.attributes[element.fieldID].attributeValue
                     } else {
                        if (item[element.fieldID]) {
                           push.id = item[element.fieldID]
                        } else {
                           push.id = 'not found'
                        }
                     }
                     data.value.push(push)
                  } catch (error) {
                     console.log(error);
                  }
               }
               data.name = element.shortEntity
               console.log('orgList: ', data);
               //console.log(data);
               commit('setPreloadLists', data)
            } catch (error) {
               console.log('Element:', element, error);
            }
         }
      },
      async getEntities({ commit, state }) {
         //data[0] = 'SHORTENTITY' AND data[1] = 'ALLOWPK'
         let dataPush = {}
         try {
            let pullData = await API.graphql({
               query: smd_listEntityObj,
               variables: {
                  active: '1',
                  PK: '#SMARTDASH',
                  shortEntity: '#ENT',
                  pageSize: 0,
               }
            })
            pullData = pullData.data.smd_listEntityObj
            let app = undefined
            if (state.organizationData.app) {
               app = state.organizationData.app
            }
            let list = JSON.parse(pullData.data)
            for (let index = 0; index < list.length; index++) {
               if ('#ENT#SYSMOD' == list[index].SK) {
                  console.log(index, list[index]);
               }
               const element = list[index];
               let itemPush = list[index]
               for (const key in element.attributes) {
                  itemPush[key] = element.attributes[key].attributeValue
               }
               delete itemPush.attributes
               let descendant = JSON.parse(itemPush['SML.descendantEntitiesWithForm'])
               let descendantPush =[]
               for (let i = 0; i < descendant.length; i++) {
                  if (app && descendant[i].app  && (descendant[i].app == app || descendant[i].app.toLowerCase()  == 'all')) {
                     descendantPush.push(descendant[i])
                  }
               }
               itemPush['SML.descendantEntitiesWithForm'] = JSON.stringify(descendantPush)
               dataPush[itemPush['metaEntity.metaShortEntity']] = itemPush
            }
            console.log(dataPush);
            commit('setEntities', dataPush)
         } catch (error) {
            console.log(error);
         }
      },
      async getProducts({ commit, state }) {
         //data[0] = 'SHORTENTITY' AND data[1] = 'ALLOWPK'
         console.log('getProducts');
         let dataPush = []
         try {
            let pullData = await API.graphql({
               query: smd_listEntityObj,
               variables: {
                  active: '1',
                  PK: state.organizationID,
                  shortEntity: 'PRO',
                  pageSize: 0,
               }
            })
            pullData = pullData.data.smd_listEntityObj
            let list = JSON.parse(pullData.data)
            for (let index = 0; index < list.length; index++) {
               const element = list[index];
               let itemPush = list[index]
               for (const key in element.attributes) {
                  if (element.attributes[key].attributeType == 'L') {
                     itemPush[key] = JSON.parse(element.attributes[key].attributeValue)
                  } else {
                     itemPush[key] = element.attributes[key].attributeValue
                  }
               }
               delete itemPush.attributes
               dataPush.push(itemPush)
            }

            commit('setProducts', dataPush)
         } catch (error) {
            console.log(error);
         }
      },
      async getModules({ commit, state }) {
         //data[0] = 'SHORTENTITY' AND data[1] = 'ALLOWPK'
         let dataPush = {}
         let menuModules = {}
         try {
            let pullData = await API.graphql({
               query: smd_listEntityObj,
               variables: {
                  active: '1',
                  PK: '#SMARTDASH',
                  shortEntity: '#PAG',
                  pageSize: 0,
               }
            })
            pullData = pullData.data.smd_listEntityObj
            let list = JSON.parse(pullData.data)
            //console.log(list);

            for (let index = 0; index < list.length; index++) {
               const element = list[index];
               let itemPush = list[index]
               for (const key in element.attributes) {
                  if (element.attributes[key].attributeType == 'L') {
                     itemPush[key] = JSON.parse(element.attributes[key].attributeValue)
                  } else {
                     itemPush[key] = element.attributes[key].attributeValue
                  }
               }
               delete itemPush.attributes
               dataPush[itemPush['modules.dataQuery']] = itemPush
               menuModules[element.SK] = itemPush
            }

            commit('setDataModules', dataPush)
            commit('setDataMenuModules', menuModules)
         } catch (error) {
            console.log(error);
         }
      },
      //Get Preload
      async getRecordLists({ commit, state }, data) {
         let dataPush = {}
         let name = ''
         let config ={}
         //console.log(data)
         if (data.attributes) {
            name = data.attributes['modules.dataQuery'].attributeValue
         } else {
            name = data['modules.dataQuery']
         }
         
         try {
            config={
               active: '1',
               pagPK: data.PK,
               pagSK: data.SK,
               dataPK: state.organizationID,
               pageSize: 0,
               lastEvaluatedKey: undefined,
               status: 'All',
            }
            switch (name) {
               case 'NOT':
                  config.range= 'Last 30 Days';
                  config.index= 'Created';
                  config.status= undefined;
               break;
            }
            //console.log('>>> Config preload Table: ',config );
            let pullData = await API.graphql({
               query: smd_listPAGWithData2,
               variables: config
            })
            pullData = pullData.data.smd_listPAGWithData2
            //console.log('pullData', JSON.parse(pullData.data));
            dataPush.list = JSON.parse(pullData.data)
            dataPush.headers = JSON.parse(pullData.header)
            if (pullData.columnsFormat != undefined && pullData.columnsFormat != '') {
               dataPush.headersV2 = JSON.parse(pullData.columnsFormat)
            } else {
               dataPush.headersV2 = []
            }
            commit('setRecordLists', { name: name, value: dataPush })
            if (name == 'NOT') {
               setTimeout(() => {
                  commit('setNotifications', { action: 'init'})
               }, 100);
            }
         } catch (error) {
            console.log(error);
         }
      },
   },
   modules: {
   },
   plugins: [createPersistedState(), createCache()],
})  
