<template>
  <div id="loading" class="loading">
    <div class="loader"></div>
  </div>
  <router-view />
  <!--Modal Edit-->
  <div v-if="false" id="modal-cart" class="modal fade modal-default" role="dialog" style="overflow-y: auto;">
    <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content">
        <div class="smart-wrap cart-wrap">
          <h6 class="col-md-12">ORDER SUMARY</h6>
          <div class="element-wrap col-md-8 product">
            <table class="table" id="myTable">
              <thead>
                <th class="index"></th>
                <th style="text-align: center;">PRODUCT</th>
                <th style="text-align: center;">PRICE</th>
                <th style="text-align: center;">QUANTITY</th>
                <th style="text-align: center;">SUBTOTAL</th>
                <th style="text-align: center;"></th>
              </thead>
              <tbody>
                <tr v-for="(item, index) in cartData" :key="index">
                  <td class="index"> {{index+1}}  </td>
                  <td>
                    <div>
                      <span  style="display:block; float:left; width:100px; margin: 0 20px 0 0;"> <img :src="JSON.parse(item.productImages.value)[0].url" alt="not found" style="    width: 100px;"> </span> 
                      <span  style="font-size: 16px;  "> <strong>{{item['product.name'].value}}</strong> <br>
                        <span style="font-size: 12px; font-weight: 400; line-height: 18px;  width: 100%;  display: block; " v-for="(zitem, zindex) in JSON.parse(item['detail'].value)" :key="zindex"> 
                          <strong>{{zindex}}: </strong> {{' '+zitem+' '}}
                        </span>
                      </span> 
                    </div>
                  </td> 
                  <td class="currency" >
                    ${{ item['product.price'].value}} 
                  </td> 
                  <td  >
                    <div class="input-wrap col-md-12">
                      <input type="number" v-model="item.qty.value" min="1" max="20" />
                    </div>
                  </td> 
                  <td class="currency" >
                    {{ ( parseInt(item['product.price'].value)*parseInt(item.qty.value)).toLocaleString('en-US', { style: 'currency', currency: 'USD'})  }}
                  </td>
                  <td class="options-td" style="width: auto;">
                    <div class="options">
                      <a   style="cursor: pointer" data-toggle="modal" data-target="#modal-file" v-on:click="deleteItemCart(item , index )" >
                        <div class="col delete" style="width: 31px; height: 31px; padding: 7px 8px!important; ">
                          <svg width="78" height="100" viewBox="0 0 78 100" fill="none" >
                            <path d="M5.57143 88.8889C5.57143 95 10.5857 100 16.7143 100H61.2857C67.4143 100 72.4286 95 72.4286 88.8889V22.2222H5.57143V88.8889ZM78 5.55556H58.5L52.9286 0H25.0714L19.5 5.55556H0V16.6667H78V5.55556Z" fill="#000" />
                          </svg>
                        </div>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="element-wrap col-md-4 wrap-payment" style="padding:0 0 0 20px;">
            <table class="table" id="myTable" style="min-width: auto;">
              <thead> 
                <th style="text-align:center;">MY ORDER</th>
              </thead>
              <tbody>
                <tr>
                  <td class="row"> 
                    <span class="col-md-6"> {{ valueCart('Subtotal:') }}</span> <span class="col-md-6" style="text-align: right;"> {{ (amountCart.subTotal).toLocaleString('en-US', { style: 'currency', currency: 'USD'}) }}</span> 
                  </td>
                  <td class="row" style="border:none;"> 
                    <span class="col-md-6"> Taxes:</span> <span class="col-md-6" style="text-align: right;">$ 00.00</span> 
                  </td>
                  <td class="row" > 
                    <span class="col-md-6">{{ valueCart('Total:')}} </span> <span class="col-md-6" style="text-align: right;">{{(amountCart.total).toLocaleString('en-US', { style: 'currency', currency: 'USD'})}}</span> 
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="row paper-payment" style="background:#fff; margin:20px 0; padding: 20px 10px 20px 10px;     box-shadow: 0px 0px 8px 0px #c4c4c480; -webkit-box-shadow: 0px 0px 8px 0px #c4c4c480; -moz-box-shadow: 0px 0px 8px 0px #c4c4c480;">
              <paper-payment :listProducts="cartData" :amountCart="amountCart"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

//Tools
import Vuex from "vuex";
import { API, Storage } from 'aws-amplify'
//Components
import PaperDisplayer from './components/papers/PaperDisplayer.vue'
import PaperPayment from './components/papers/PaperPayment.vue'
import UiButton from './components/ui/UiButton.vue'
//GRAPHQL
import {smd_setItemState} from './graphql/mutations'
import {smd_getCart} from './graphql/queries'

export default {
  components: {
    PaperDisplayer,
    UiButton,
    PaperPayment,
  },
  data() {
    return {
      content: null,
      //Data
      email: "",
      name: "",
      phone: "",
      cno: "",
      date: "",
      cvv: "",
      zip: "",
      //Amount
      amountCart:{
        subTotal: 0,
        tax: 0,
        total: 0,
      }
    }
  },
  created() {
    
    try {
      document.getElementById('title').innerText = ''
      document.getElementById('favicon').href = JSON.parse(this.organizationData.favicon.value)[0].url
    } catch (error) {
      //console.log(error);
    }
  },
  methods: {
    ...Vuex.mapMutations(['setCartData']),
    //Cart
    valueCart( amount ){
      let value = 0
      switch (amount) {
        case 'Subtotal:':     
          for (let index = 0; index < this.cartData.length; index++) {
            const element = this.cartData[index]
            value = parseInt(element['product.price'].value)*parseInt(element.qty.value)+value
          }
          this.amountCart.subTotal = value
        break;  
        case 'Total:':
          this.amountCart.total = this.amountCart.subTotal + this.amountCart.tax
        break
      }

      return amount
    },
    async deleteItemCart(item, index){
      try {
        let push = await API.graphql({
          query: smd_setItemState,
          variables: { PK: item.PK, SK: item.SK, state: '0'},
        });
        console.log(push);
        this.deleteItem()
      } catch (error) {
        console.log(error);
      }
    },
    async deleteItem(){
      try {
        let pullData = ''
        let org = this.publicOrganization
        console.log( org );

        pullData  = await API.graphql({
          query: smd_getCart,
          variables:{
            orgPK:org
          }
        }) 
        console.log(pullData);
        pullData = pullData.data.smd_getCart
        console.log(JSON.parse(pullData.data));
        this.setCartData(JSON.parse(pullData.data))
      } catch (error) {
        console.log(error);
      }
    }
    
  },
  computed:{
    ...Vuex.mapState(['cartData','publicOrganization','organizationData'])
  }
}
</script> 
<style>
/* 'Montserrat' */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600;700;900&display=swap");
/* 'Raleway' */
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
/* 'Josefin Sans' */
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
/* 'Indie Flower' */
@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap');
/* 'Patrick Hand' */
@import url('https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap');
/* 'Caveat' */
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;600;700&display=swap');
/* 'Courgette' */
@import url('https://fonts.googleapis.com/css2?family=Courgette&display=swap');
/* 'Michroma' */
@import url('https://fonts.googleapis.com/css2?family=Michroma&display=swap');
/* 'Handlee' */
@import url('https://fonts.googleapis.com/css2?family=Handlee&display=swap');
/* 'Turret Road' */
@import url('https://fonts.googleapis.com/css2?family=Turret+Road:wght@200;300;400;500;700;800&display=swap');
/* 'Righteous' */
@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');
/* 'Love Ya Like A Sister' */
@import url('https://fonts.googleapis.com/css2?family=Love+Ya+Like+A+Sister&display=swap');
/* 'Happy Monkey' */
@import url('https://fonts.googleapis.com/css2?family=Happy+Monkey&display=swap');
/* 'Sail' */
@import url('https://fonts.googleapis.com/css2?family=Sail&display=swap');
/* 'Yeon Sung' */
@import url('https://fonts.googleapis.com/css2?family=Yeon+Sung&display=swap');
/* 'Unkempt' */
@import url('https://fonts.googleapis.com/css2?family=Unkempt:wght@400;700&display=swap');
/* 'Mogra' */
@import url('https://fonts.googleapis.com/css2?family=Mogra&display=swap');
/* 'Permanent Marker' */
@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');
/* 'Gochi Hand' */
@import url('https://fonts.googleapis.com/css2?family=Crafty+Girls&display=swap');
/* 'Crafty Girls' */
@import url('https://fonts.googleapis.com/css2?family=Gochi+Hand&display=swap');
@font-face {
  font-family: 'Warpaint';
  src: url('./assets/fonts/warpaint/Warpaint.eot');
  src: url('./assets/fonts/warpaint/Warpaint.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/warpaint/Warpaint.woff2') format('woff2'),
      url('./assets/fonts/warpaint/Warpaint.woff') format('woff'),
      url('./assets/fonts/warpaint/Warpaint.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Gloss And Bloom';
  src: url('./assets/fonts/glossandbloom/GlossAndBloom.eot');
  src: url('./assets/fonts/glossandbloom/GlossAndBloom.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/glossandbloom/GlossAndBloom.woff2') format('woff2'),
      url('./assets/fonts/glossandbloom/GlossAndBloom.woff') format('woff'),
      url('./assets/fonts/glossandbloom/GlossAndBloom.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
    font-family: 'Rolling';
    src: url('./assets/fonts/rolling/RollingBold-PersonalUse.eot');
    src: url('./assets/fonts/rolling/RollingBold-PersonalUse.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/rolling/RollingBold-PersonalUse.woff2') format('woff2'),
        url('./assets/fonts/rolling/RollingBold-PersonalUse.woff') format('woff'),
        url('./assets/fonts/rolling/RollingBold-PersonalUse.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'LEMON MILK';
    src: url('./assets/fonts/lemonmilk/LEMONMILK-LightItalic.eot');
    src: url('./assets/fonts/lemonmilk/LEMONMILK-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/lemonmilk/LEMONMILK-LightItalic.woff2') format('woff2'),
        url('./assets/fonts/lemonmilk/LEMONMILK-LightItalic.woff') format('woff'),
        url('./assets/fonts/lemonmilk/LEMONMILK-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'LEMON MILK';
    src: url('./assets/fonts/lemonmilk/LEMONMILK-Light.eot');
    src: url('./assets/fonts/lemonmilk/LEMONMILK-Light.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/lemonmilk/LEMONMILK-Light.woff2') format('woff2'),
        url('./assets/fonts/lemonmilk/LEMONMILK-Light.woff') format('woff'),
        url('./assets/fonts/lemonmilk/LEMONMILK-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'LEMON MILK';
    src: url('./assets/fonts/lemonmilk/LEMONMILK-Regular.eot');
    src: url('./assets/fonts/lemonmilk/LEMONMILK-Regular.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/lemonmilk/LEMONMILK-Regular.woff2') format('woff2'),
        url('./assets/fonts/lemonmilk/LEMONMILK-Regular.woff') format('woff'),
        url('./assets/fonts/lemonmilk/LEMONMILK-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'LEMON MILK';
    src: url('./assets/fonts/lemonmilk/LEMONMILK-Bold.eot');
    src: url('./assets/fonts/lemonmilk/LEMONMILK-Bold.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/lemonmilk/LEMONMILK-Bold.woff2') format('woff2'),
        url('./assets/fonts/lemonmilk/LEMONMILK-Bold.woff') format('woff'),
        url('./assets/fonts/lemonmilk/LEMONMILK-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'LEMON MILK';
    src: url('./assets/fonts/lemonmilk/LEMONMILK-Medium.eot');
    src: url('./assets/fonts/lemonmilk/LEMONMILK-Medium.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/lemonmilk/LEMONMILK-Medium.woff2') format('woff2'),
        url('./assets/fonts/lemonmilk/LEMONMILK-Medium.woff') format('woff'),
        url('./assets/fonts/lemonmilk/LEMONMILK-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'LEMON MILK';
    src: url('./assets/fonts/lemonmilk/LEMONMILK-BoldItalic.eot');
    src: url('./assets/fonts/lemonmilk/LEMONMILK-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/lemonmilk/LEMONMILK-BoldItalic.woff2') format('woff2'),
        url('./assets/fonts/lemonmilk/LEMONMILK-BoldItalic.woff') format('woff'),
        url('./assets/fonts/lemonmilk/LEMONMILK-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'LEMON MILK';
    src: url('./assets/fonts/lemonmilk/LEMONMILK-MediumItalic.eot');
    src: url('./assets/fonts/lemonmilk/LEMONMILK-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/lemonmilk/LEMONMILK-MediumItalic.woff2') format('woff2'),
        url('./assets/fonts/lemonmilk/LEMONMILK-MediumItalic.woff') format('woff'),
        url('./assets/fonts/lemonmilk/LEMONMILK-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'LEMON MILK';
    src: url('./assets/fonts/lemonmilk/LEMONMILK-RegularItalic.eot');
    src: url('./assets/fonts/lemonmilk/LEMONMILK-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/lemonmilk/LEMONMILK-RegularItalic.woff2') format('woff2'),
        url('./assets/fonts/lemonmilk/LEMONMILK-RegularItalic.woff') format('woff'),
        url('./assets/fonts/lemonmilk/LEMONMILK-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Imstory';
    src: url('./assets/fonts/imstory/Imstory.eot');
    src: url('./assets/fonts/imstory/Imstory.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/imstory/Imstory.woff2') format('woff2'),
        url('./assets/fonts/imstory/Imstory.woff') format('woff'),
        url('./assets/fonts/imstory/Imstory.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'bunderan';
    src: url('./assets/fonts/bunderan/bunderan-Bold.eot');
    src: url('./assets/fonts/bunderan/bunderan-Bold.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/bunderan/bunderan-Bold.woff2') format('woff2'),
        url('./assets/fonts/bunderan/bunderan-Bold.woff') format('woff'),
        url('./assets/fonts/bunderan/bunderan-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'bunderan';
    src: url('./assets/fonts/bunderan/bunderan.eot');
    src: url('./assets/fonts/bunderan/bunderan.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/bunderan/bunderan.woff2') format('woff2'),
        url('./assets/fonts/bunderan/bunderan.woff') format('woff'),
        url('./assets/fonts/bunderan/bunderan.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
</style>
<style lang="less">
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}
body, html {
  background-color: transparent;
  color: #373F4A;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  height: 100%;
}

  /* Define how SqPaymentForm iframes should look */
  .sq-input {
    height: 56px;
    box-sizing: border-box;
    border: 1px solid #E0E2E3;
    background-color: white;
    border-radius: 6px;
    display: inline-block;
    -webkit-transition: border-color .2s ease-in-out;
      -moz-transition: border-color .2s ease-in-out;
        -ms-transition: border-color .2s ease-in-out;
            transition: border-color .2s ease-in-out;
  }
  /* Define how SqPaymentForm iframes should look when they have focus */
  .sq-input--focus {
    border: 1px solid #4A90E2;
  }
  /* Define how SqPaymentForm iframes should look when they contain invalid values */
  .sq-input--error {
    border: 1px solid #E02F2F;
  }
  #sq-card-number {
    margin-bottom: 16px;
  }
  /* Customize the "Pay with Credit Card" button */
  .button-credit-card {
    width: 100%;
    height: 56px;
    margin-top: 10px;
    background: #4A90E2;
    border-radius: 6px;
    cursor: pointer;
    display: block;
    color: #FFFFFF;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    letter-spacing: 0;
    text-align: center;
    -webkit-transition: background .2s ease-in-out;
      -moz-transition: background .2s ease-in-out;
        -ms-transition: background .2s ease-in-out;
            transition: background .2s ease-in-out;
  }
  .button-credit-card:hover {
    background-color: #4281CB;
  }
  .hosted-field {
    font-size: 14px;
    width: 100%;
    padding: 10px;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #a9a9a900;
    background: #D9D9D9;
    color: #5A5A5A;
    position: relative;
    height: 40px;
  }

  .hosted-fields--label {
    font-size: 14px;
    display: block;
    margin:10px 0 0 0;
  }

  .button-container {
    display: block;
    text-align: center;
  }

  .button {
    outline:none!important;
    cursor: pointer;
    font-weight: 500;
    line-height: inherit;
    position: relative;
    text-decoration: none;
    text-align: center;
    border-style: solid;
    border-width: 1px;
    border-radius: 3px;
    -webkit-appearance: none;
    -moz-appearance: none;
    display: inline-block;
  }

  .button--small {
    padding: 10px 20px;
    font-size: 0.875rem;
  }

  .button--green {
    outline: none;
    background-color: #64d18a;
    border-color: #64d18a;
    color: white;
    transition: all 200ms ease;
  }

  .button--green:hover {
    background-color: #8bdda8;
    color: white;
  }

  .braintree-hosted-fields-focused {
    border: 1px solid #64d18a;
    border-radius: 1px;
    background-position: left bottom;
  }

  .braintree-hosted-fields-invalid {
    border: 1px solid #ed574a;
  }

  //.braintree-hosted-fields-valid {}

  #cardForm {
    max-width: 100%;
    margin: 0 auto;
    padding: 0;
  }
  //Global Styles
  *{
    margin: 0;
    padding: 0;
    font-family: 'Montserrat';
    box-sizing: border-box;
  }
  .clear(){
    background: transparent;
    color: transparent;
    display: block;
    position: relative;
  }
  ::-webkit-scrollbar {
    width: 14px;
    .hover;
  }
  &::-webkit-scrollbar {
      display: none
    }
  ::-webkit-scrollbar-thumb {
    border: 4px solid #00000000;
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #AAAAAA;
  }
  
  .miniShadow{
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
  }
  .middleShadow{
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
  }
  body{
    background: #f0f0f0;
    overflow-x: hidden;
    box-sizing: border-box;
  }
  .row{
    margin: 0;
    padding: 0;
  }
  p{
    margin: 0;
  }
  a{
    text-decoration: none!important;
    &:-moz-any{
      text-decoration: none;
    }
  }
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12,
  h1, h2 ,h3 ,h4 ,h5 ,h6{
    padding:0;
    margin: 0;
  }
  .container-in{
    width: 100%;
    max-width: 1100px;
    margin:0 auto;
    display:block;
    box-sizing: border-box;
    z-index: 9;
    padding: 40px;
    &.sheet, .sheet {
      box-sizing: border-box;
      background: #FFFFFF;
      border-radius: 10px;
      box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.29);
      -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.29);
      -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.29);
      padding:30px;
      //margin:20px ;
    }
    &.w-800{
      max-width: 800px;
    }
  }
  .spaceBetween(){
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .spaceBetween{
    .spaceBetween;
  }
  .absoluteCenterFlex(){
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .absoluteCenterFlex{
    .absoluteCenterFlex;
  }
  .hover(){ 
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
  }
  .hover{
    .hover();
  }
  .hover-5s(){ 
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
  }
  .rotate360(){ 
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);Z
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  .shadow(){
    box-shadow: 0px 0px 8px 0px #c4c4c480 ;
    -webkit-box-shadow: 0px 0px 8px 0px #c4c4c480;
    -moz-box-shadow: 0px 0px 8px 0px #c4c4c480;
  }
  .shadowBox(){
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
  }
  .shadowBox{
    .shadowBox;
  }
  .shadowBoxHover{
    &:hover{
      .shadowBox;
    }
  }
  .shadow-none(){
    box-shadow: 0px 0px 0px 0px #00000000;
    -webkit-box-shadow: 0px 0px 0px 0px #00000000;
    -moz-box-shadow: 0px 0px 0px 0px #00000000;
  }
  .hoverOpacity{
    .hover;
    opacity: 1;
    &:hover{
      .hover;
      opacity: 0.8;
    }
  }
  .background-full () {
    -webkit-background-size: 100%;
    -moz-background-size: 100%;
    -o-background-size: 100%;
    background-size: 100%;
  }
  .tooltip-inner {
    text-align: left;
    padding:20px;
  }
  .nav-tabs .nav-link{
    color: #000;
  }
  .search {
    position: absolute;
    top: 0;
    z-index: -1;
    opacity: 0;
    .hover;
  }
  .addBlock{
    .absoluteCenterFlex;
    padding: 20px;
    cursor: pointer;
    svg{
      .hover-5s;
      opacity:0.5;
      border: 5px solid #ccc;
    }
    &:hover{
      svg{
        .hover-5s;
        opacity:1;
      }
    }
  }
  .searchTable {
    display: none;
  }
  .ghost{
    opacity: 0;
    .hover;
  }
  .none{
    display: none;
  }
  .smart-text{
    color: #5a5a5a;
    &:hover{
      color: #5a5a5a;
    }
    &.t-center{
      text-align: center;
    }
    &.t-10{ font-size: 10px; }
    &.t-12{ font-size: 12px; }
    &.t-14{ font-size: 14px; }
    &.t-16{ font-size: 16px; }
    &.t-strong{ font-weight: bold; }
  }
  .center{
    margin: auto;
    display: block;
    position: relative;
  }
  .last-block{
    //border:none;
  }
  .screen-wrap{
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    .absoluteCenterFlex;
  }
  .dropdown-menu{
    overflow: auto;
    max-height: 98vh;
  }
  .questionnaires-wrap{
    overflow:hidden ;
    display: block;
    height: 100vh;
    padding: 0 20px;
    background:#fff;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    .absoluteCenterFlex;
    &::-webkit-scrollbar {
      display: none
    }
    .container-in{
      height: 94vh;
    }
    .header{
      height: 80px;
      margin: 0 0 20px 0;
      .button-q{
        justify-content: flex-end;
        
      }
    }
    .body{
      height: calc( 100% - 50px) ;
      overflow-y:auto ;
      overflow-x:hidden ;
      display: block;
      &::-webkit-scrollbar {
        display: none
      }
    }
  }
  //.swal2-styled.swal2-confirm{
    //background-color: #3A90EF!important;
  //}
  //Custom Imputs
  .m-phone-number-input__country-flag{
    bottom: 24px!important;
  }
  .m-phone-number-input{
    padding: 5px;
  }
  .m-select{
    width: 120px!important;
  }
  .m-input-label{
    display:none!important;
  }
  .m-input-wrapper{
    background: transparent!important;
    border:none!important;
  }
  //Back Grounds
  .bg{
    padding:1px;
    width: 100vw;
    height: 100vh;
    position: fixed;
    display: block;
    top:0;
    z-index: -1;
    &.blue{
      background: url(./assets/img/bg_blue.svg) no-repeat center center;
      .background-full;
    }
  }
  amplify-s3-image {
    --width: 100%;
  }
  //Section Styles ---
  .wrap{
    width: 100%;
  }
  .sidebar {
    background: #262626;
    &.desk{
      position: fixed;
      left: 0;
      top: 0;
      height: 100%;
      width: 78px;
      padding: 6px 15px;
      z-index: 99;
    }
    &.phone{
      width: 100%;
      height: 100vh;
      padding: 20px;
      opacity: 0;
      z-index: -99;
      display: block;
      position: fixed
    }
    //overflow: auto;
    .hover-5s;
    .logo-details {
      height: 60px;
      display: flex;
      align-items: center;
      position: relative;
      .logo_name {
        width: calc(100% - 40px);
        color: #fff;
        font-size: 20px;
        font-weight: 600;
        opacity: 0;
        .hover-5s;
      }
      #btn-sidebar {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        font-size: 22px;
        transition: all 0.4s ease;
        font-size: 23px;
        text-align: center;
        cursor: pointer;
        .hover-5s;
      }
      i {
        .hover;
        color: #fff;
        height: 60px;
        min-width: 50px;
        font-size: 18px;
        text-align: center;
        line-height: 60px;
      }
    }
    
    .nav-list {
      margin-top: 20px;
      height: auto;
    }
    li {
      position: relative;
      margin: 8px 0;
      list-style: none;
      .tooltip {
        position: absolute;
        top: -20px;
        left: calc(100% + 15px);
        z-index: 3;
        background: #fff;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
        padding: 6px 12px;
        border-radius: 4px;
        font-size: 15px;
        font-weight: 400;
        opacity: 0;
        white-space: nowrap;
        pointer-events: none;
        transition: 0s;
      }
      &:hover {
        .tooltip {
          opacity: 1;
          pointer-events: auto;
          transition: all 0.4s ease;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .module {
        background: #212121;
        overflow: hidden;
        position: relative;
        .hover;
        .module-block {
          font-size: 18px;
          display: flex;
          text-align: left;
          align-items: center;
          color: #FFF;
          i {
            .hover;
            color: #fff;
            height: 60px;
            min-width: 50px;
            text-align: center;
            line-height: 60px;
          }
          span{
            width: 100%;
            overflow: hidden;
            padding: 10px 0;
            font-size: 0;
            opacity: 0;
            .hover();
          }
          
        }
        .module-group{
          opacity: 0;
          .hover;
          span{
            width: 100%;
            padding: 0;
            color: #FFF;
            opacity: 0.6;
            display: block;
            font-size: 0px;
            .hover;
            i{
              font-size: 0px;
            }
            &:hover{
              padding: 0 20px 15px 22px;
              .hover;
              opacity: 1;
            }
          }
        }
        &:hover {
          background: #161616;
          .hover;
        }
      }
    }
    input {
      font-size: 15px;
      color: rgb(16, 16, 16);
      font-weight: 400;
      height: 40px;
      width: 40px;
      border: none;
      outline: none;
      transition: all 0.3s ease;
      background: #ffffff;
      padding: 10px;
      .hover;
      &:hover{
        //background: #161616;
      }
    }
    input::placeholder{
      font-size: 15px;
      color: #000000!important;
      .hover;
    }
    .bxIcon {
      position: absolute;
      transition: all 0.3s ease 0s;
      color: #fff;
      height: 40px;
      min-width: 40px;
      text-align: center;
      line-height: 42px;
      background: #212121;
      &:hover {
        background: #FFF;
        color: #161616;
      }
    }
    .nav-search{
      width: 100%;
      .bx-x {
        position: absolute;
        color: transparent;
        .hover;
        right: 0;
        padding: 10px 5px;
        font-size: 20px;
        z-index: 999;
      }
      &:hover{
        .bx-x {
          .hover;
          color: #262626;
          background: #00000014;
        }
      }
    }
  }
  .sidebar.show {
    &.desk{
      width: 250px;
      z-index: 99;
    }
    &.phone{
      opacity: 1;
      width: 100%;
      height: 100vh;
      z-index: 999;
    }
    .logo-details {
      .icon {
        opacity: 1;
      }
      .logo_name {
        opacity: 1;
      }
      #btn-sidebar {
        text-align: right;
      }
    }
    li {
      .tooltip {                                                                                                    
        display: none;
      }
      .module {
        .module-block {
          span{
            font-size: 15px;
            opacity: 1;
            pointer-events: auto;
            .hover();
          }

        }
        .module-group{
          opacity: 1;
          .hover;   
          span{
            padding: 0 20px 15px 20px;
            font-size: 14px;
            i{
              font-size: 10px;
            }
          }
        }
      }
    }
    input {
      padding: 0 10px 0 60px;
      width: 100%;
      .hover;
    }
    input::placeholder{
      font-size: 15px;
      color: #ffffff;
      .hover;
    }
    .bx-search {
      &:hover {
        background: #11101d;
        color: #FFF;
      }
    }

  }
  .sidebar.desk.show ~ .paper{
    left: 250px;
    width: calc(100% - 250px);
  }
  .paper{
    .calls{
      padding: 7px 20px;
      .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;
        input {
          opacity: 0;
          width: 0;
          height: 0;
        }
        input:checked + .slider {
          background-color: #2196F3;
        }

        input:focus + .slider {
          box-shadow: 0 0 1px #2196F3;
        }

        input:checked + .slider:before {
          -webkit-transform: translateX(26px);
          -ms-transform: translateX(26px);
          transform: translateX(26px);
        }

        .slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #ccc;
          -webkit-transition: .4s;
          transition: 0.4s;
          overflow: auto;
          &.round{
            border-radius: 34px;
            &:before {
              border-radius: 50%;
            }
          }
          &:before {
            position: absolute;
            content: "";
            height: 26px;
            width: 26px;
            left: 4px;
            bottom: 4px;
            background-color: white;
            -webkit-transition: .4s;
            transition: .4s;
            
          }
        }
      }
    }
    position: relative;
    background: #f0f0f0;
    min-height: 100vh;
    top: 0;
    left: 78px;
    width: calc(100% - 78px);
    .hover-5s;
    z-index: 99;
    padding: 20px;
    .header{
      position: relative;
      max-width: 100vw;
      width: 100%;
      .absoluteCenterFlex;
      justify-content: center;
      flex-wrap: nowrap;
      .menu{
        width: 50px;
        height: 50px;
        padding: 12px;
        background: #d9d9d9;
        border-radius: 2px;
        .hover;
      }
      
      &.version{
        background: red;
        color: #fff;
        padding: 10px;
      }
      padding: 0;
      border-bottom: 1px solid #00000052;
      width: 100%;
      h6{
        width: auto;
        float: left;
        font-size: 20px;
        padding: 15px 0 0 0;
        margin: 0;
      }
      .home-bar{
        width: 100%;
        .absoluteCenterFlex;
        justify-content: flex-start;
        flex-wrap: nowrap;
        font-size: 20px;
        .hover;
        a{
          margin: 0 0 2px 0;
          padding: 8px 16px;
          display: block;
          float: left;
          border-radius: 10px;
          color:#494949;
          text-decoration: none;
          font-weight: 600;
          .hover;
          i{
            padding: 12px 10px 10px 0;
            font-size: 22px;
          }
          &:hover{
            .hover;
            background: #e9e9e9;
          }
        }
        .fliendlyView{
          display: block;
          float: left;
          margin: 0 20px;
          p{
            font-size: 12px;
            font-weight: 600;
            color:#494949;
          }
          .switch {
            margin: 0 auto;
            display: block;
            position: relative;
            width: 60px;
            height: 34px;
            input {
              opacity: 0;
              width: 0;
              height: 0;
            }
            input:checked + .slider {
              background-color: #2196F3;
            }
  
            input:focus + .slider {
              box-shadow: 0 0 1px #2196F3;
            }
  
            input:checked + .slider:before {
              -webkit-transform: translateX(26px);
              -ms-transform: translateX(26px);
              transform: translateX(26px);
            }
  
            .slider {
              position: absolute;
              cursor: pointer;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background-color: #ccc;
              -webkit-transition: .4s;
              transition: .4s;
              &.round{
                border-radius: 34px;
                &:before {
                  border-radius: 50%;
                }
              }
              &:before {
                position: absolute;
                content: "";
                height: 26px;
                width: 26px;
                left: 4px;
                bottom: 4px;
                background-color: white;
                -webkit-transition: .4s;
                transition: .4s;
              }
            }
          }
        }
      }
      .control-bar{
        width: 100%;
        .absoluteCenterFlex;
        justify-content: flex-end;
        flex-wrap: nowrap;
        .calls{
          padding: 7px 20px;
          .switch {
            position: relative;
            display: inline-block;
            width: 60px;
            height: 34px;
            input {
              opacity: 0;
              width: 0;
              height: 0;
            }
            input:checked + .slider {
              background-color: #2196F3;
            }

            input:focus + .slider {
              box-shadow: 0 0 1px #2196F3;
            }

            input:checked + .slider:before {
              -webkit-transform: translateX(26px);
              -ms-transform: translateX(26px);
              transform: translateX(26px);
            }

            .slider {
              position: absolute;
              cursor: pointer;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background-color: #ccc;
              -webkit-transition: .4s;
              transition: .4s;
              &.round{
                border-radius: 34px;
                &:before {
                  border-radius: 50%;
                }
              }
              &:before {
                position: absolute;
                content: "";
                height: 26px;
                width: 26px;
                left: 4px;
                bottom: 4px;
                background-color: white;
                -webkit-transition: .4s;
                transition: .4s;
              }
            }
          }
        }
        .notifications{
          padding: 10px 20px;
          i{
            color: #494949;
            font-size: 25px;
          }
          .noti{
            border-radius: 50%;
            padding: 2px 5px;
            color: #FFF;
            font-size: 11px;
            background: #ff0000;
            width: 20px;
            height: 20px;
            position: absolute;
            right: -5px;
            bottom: 0;
          } 
        }
        .profile{
          a{
            overflow: hidden;
            display: block;
            i{
              float: left;
              padding: 10px ;
              border-radius: 50%;
              background: #e0e0e0;
              color: #494949;
              font-size: 25px;
            }
            p{
              float: left;
              margin: 0;
              font-weight: 700;
              font-size: 20px;
              padding: 8px 0 0 10px;
            }
          }
        }

      }
      .searchContact{
        input{
          border-radius: 20px;
        }
      }
      
    } 
    .dash{
      width: 100%;
    }
    .footer{
      border-top: 1px solid #00000052;
      padding: 10px 0 0 0;
      width: 100%;
      .col{
        padding: 0;
        &.two{
          text-align: right;
        }
      }
    }
  }
  .calendar-wrap{
    background: #fff;
    .col-calendar{
      width: 340px;
      padding: 20px 20px 20px 10px;
      box-sizing: border-box;
    }
    .col-content{
      width: calc(100% - 340px );
      box-sizing: border-box;
      .disabled{
        opacity: 0.5;
        pointer-events: none;
      }
      h4{
        text-align: center;
        margin: 20px 0 0 0;
        color: #5A5A5A;
        font-weight: 600;
      }
      .meet{
        border: 1px solid #0059ff;
        width: 100%;
        float: left;
        margin: 10px 0;
        text-align: center;
        color: #5A5A5A;
        font-weight: 600;
        a{
          padding: 10px;
          display: block;
          width: 100%;
        }
        .collapse{
          padding: 10px;
        }
      }
    }
    .wrap-header{
      width: 100%;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      padding: 20px 20px 10px;
      .pre,.next{
        width: 25px;  
        height: 25px;
        cursor: pointer;
        svg{
          width: 100%;
          height: 100%;
        }
      }
      .info{
        width: calc(100% - 50px);
      }
    }
  }
  .postView{
    .element-wrap{
      padding: 10px;
      margin: 0!important;
      .envelop{
        border-radius: 20px;
        padding: 20px;
        display: flex;
        align-items: center;
        flex-direction: column;
        flex-wrap: nowrap;
        height: 100%;
        justify-content: space-between;
        .hover;
        &:hover{
          background: #ffffff;
          .hover;
          //.shadowBox;
        }
        input[type="checkbox"]{
          position: absolute;
          right: 30px;
          top: 30px;
          width: 40px;
          height: 40px;
        }
        img{
          width: 100%;
          //max-width: 600px;
        }
        h4{
          margin: 20px 0 0 0;
        }
        p{
          text-align: center;
          margin: 10px 0;
        }
      }
    }
  }
  //WRAP
  .smart-wrap{
    flex-wrap: wrap;
    display: flex;
    &.dropdown-menu{
      display: none;
    }
    width: 100%;
    box-sizing: border-box;
    //overflow: auto;
    .hover;
    .label{
      label{
        font-size: 14px!important;
      }
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      font-size: 14px!important;
      .options{
        input, textarea, select{
          background: #D9D9D9;
          padding: 2px;
          font-weight: normal;
          height: auto;
          font-size: 16px;
          pointer-events: auto;
        }
      }
      .dropdown{
        float: left;
        .dropdown-menu{
          min-width: 250px;
        }
      }
      svg{
        width: 25px;
        height: 18px;
      }
    }
    
    .input-smart{
      margin: 5px 0;
      background: rgba(0, 0, 0, 0.04);
    }
    .input-wrap{
      &.required{
        label{
          color: #ff3030af;
        }
        input, textarea, select{
          border: 1px solid #ff3030af;
        }
        input:focus, textarea:focus, select:focus,
        input:hover, textarea:hover, select:hover {
          outline:none;
          border-color: #ff3030af;
      }
      }
      label{
        float: left;
        color: #5A5A5A;
        font-weight: 600;
        font-size: 12px;
        margin: 0!important;
      }
      .hover;
      border: 1px dashed transparent;
      padding: 5px;
      position: relative;
      input, select{
        height: 40px;
        &.table-input{
          height: 36px;
        }
        &.box-boolean{
          border-radius: 50%;
        }
      }
      .inputMimo{
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      input, textarea, select, .inputMimo{
        font-size: 15px;
        font-family: 'Montserrat';
        width: 100%;
        padding: 10px;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        border: none;
        border-bottom: 1px solid #a9a9a900;
        background: #D9D9D9;
        color: #5A5A5A;
        position: relative;
        .hover;
        &.disable-input{
          background: transparent;
          border: none!important;
        }
        
      }
      textarea{
        //min-height: 50px;
      }
      input[type="checkbox"]{
        width: 20px;
        height: 20px;
      }
      .check-wrap{
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        .envelop{
          width: 100%;
          //height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        input{
          width: 30px;
          height: 30px;
          margin: 5px 0 0 0;
        }
        .text{ 
          padding: 10px 0 20px 10px;
        }
      }
      &.html{ //TABS STYLE
        width: 100%;
        li{
          text-align: center;
          padding: 0;
          a{
            display: block;
            padding: 10px 0;
            .hover-5s();
            color: rgb(52, 52, 52);
            &.active{
              background: #0000000a;
            }
          }
        }
        .tab-content{
          #preview-wrap{
            background: #0000000a;
            margin: 20px 0;
          }
        }
      }
      input:focus-visible, textarea:focus-visible, select:focus-visible {
        border: none;
        outline:none;
      }
      input:focus, textarea:focus, select:focus,
      input:hover, textarea:hover, select:hover {
        outline:none;
        border-bottom: 1px solid #a9a9a9;
        .hover;
      }
    }
    .layout-wrap{
      label{
        float: left;
        color: #5A5A5A;
        font-weight: 600;
        font-size: 12px!important;
        margin: 0!important;
      }
      border: 1px dashed transparent;
      padding: 10px;
      .hover;
      position: relative;
      &:hover{
        .hover;
      }
    }   
    .element-wrap, .module-wrap{
      //border: 1px dashed transparent;
      position: relative;
      //padding: 10px;  
      overflow: unset;
      margin: 15px 0;
      .hover;
      &.inhert{
        border: none;
        padding: 0;
      }
      //

      //Tables
      &.table-wrap{
        .shadowBox();
        //overflow: auto;
        max-width: 100vw;
        width: 100%;
      }
      .table{
        //overflow: hidden;
        min-width: 500px;
        width: 100%;
        background: #fff;
        margin: 0;
        thead{
          border: none;
          th{
            border: none;
            color: #383838;
            font-weight: 700;
            font-size: 14px;
            padding: 10px;
            &.index{
              background: #D9D9D9;
              width: 20px;
              text-align: center;
            }
            &.contact{
              background: #8d8d8d;
            }
          }
        }
        tbody{
          tr{
            td{
              padding: 10px;
              border-top: 1px solid  #00000052;
              font-size: 12px;
              font-weight: 500;
              color: #333333;
              vertical-align: middle;
              &.options-td{
                padding: 10px !important;
                width: 160px;
              }
              &.currency{
                text-align: end;
              }
              &.img{
                width: 100px;
                height: 100px;
                overflow: hidden;
                img{
                  width: 100%;
                  height: 100px;
                }
              }
              &.index{
                background: #D9D9D9;
                text-align: center;
              }
              &.td-calendar{
                padding: 5px;
              }
              .meet{
                display: block;
                float: left;
                background: #00000023;
                padding: 20px;
                border-radius: 10px;
                margin: 5px;
                .hover;
                &:hover{
                  .hover;
                  background: #00000038;
                }
              }
            }
            &.tr-hover{
              cursor: pointer;
              &:hover{
                .hover();
                background: #f3f3f3;
              }
            }
          }
        }
      }
      
      //Charts
      .charts{ 
        padding: 0 20px;
        height: auto;
        overflow: hidden;
        position: relative;
        &.menu{
          .color{
            width: 14px;
            height: 14px;
            display: inline-block;
          }
        }
        &.bars{
          min-height: 300px;
          .hover;
          .graph{
            padding: 0 20px;
            position: absolute;
            z-index: 2;
            width: calc(100% - 60px);
            height: calc(100% - 30px);
            top: 0;
            right: 0;
            //border-left: 1px solid #000 ;
            border-bottom: 1px solid #000 ;
            display: flex;
            align-items: flex-end;font-weight: bold;
            .hover;
            .bar{
              margin: 0 1px;
              width: 20%;
              position: relative;
              .hover;
              .text{
                position: absolute;
                bottom: -30px;
                text-align: center;
                width: 100%;
                height: 30px;
                line-height: 30px;
              }
              .area{
                background: #00000073;
                width: 100%;
                max-width: 5x0px;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                display: block;
                margin: 0 auto;
                .hover;
              }
              .serie{
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                display: flex;
                margin: 0 auto;
                align-items: flex-end;
                .hover;
                a{
                  padding: 3px;
                  .border-bar{
                    border-top: 3px solid #ffffff00;
                    border-right: 3px solid #ffffff00;
                    width: 100%;
                    height: 100%;
                    display: block;
                    .hover-5s;
                    &:hover{
                      .hover-5s;
                      border-top: 3px solid #ffffff27;
                      border-right: 3px solid #ffffff27;
                    }
                  }
                }
              }
            }
          }
          .y{
            height: calc(100% - 30px);
          }
          .x{
            height: 30px;
          }
        }
        &.stackBar{
          min-height: 300px;
          .hover;
          .graph{
            position: absolute;
            z-index: 2;
            width: calc(100% - 60px);
            height: calc(100% - 30px);
            top: 0;
            right: 0;
            border-left: 1px solid #000 ;
            border-bottom: 1px solid #000 ;
            display: flex;
            align-items: flex-end;font-weight: bold;
            .hover;
            .bar{
              &.horizontal{
                width: 100%;
                height: 40px;
                margin:  10px auto;
                .area{
                  display: inline-block;
                  height: 100%;
                }
              }
              &.vertical{
                width: 100%;
                max-width: 40px;
                height: 100%;
                margin:  0 auto;
                .area{
                  display: block;
                  width: 100%;
                }
              }
            }
          }
          .parameters{
            .x{
              height: 30px;
            }
          }
        }
        &.pie{
          .graph{
            height: 300px;
            position: relative;
            width: 300px;
            .hover;
            .bar {
              border-radius: 50%;
              height: 100%;
              position: absolute;
              width: 100%;
              .hover;
              &.dona{
                z-index: 99;
                width: 250px;
                height: 250px;
                position: absolute;
                background: rgba(0, 0, 0, 0.131);
                top: 0;
                left: 0;
                right: 0;
                display: flex;
                margin: 25px auto;
                padding: 25px 0;
                text-align: center;
                align-items: center;
                box-shadow: 0px 0px 51px 0px rgba(0,0,0,0.55) inset;
                -webkit-box-shadow: 0px 0px 51px 0px rgba(0,0,0,0.55) inset;
                -moz-box-shadow: 0px 0px 51px 0px rgba(0,0,0,0.55) inset;
                span{
                  width: 100%;
                  color: #fff;
                }
              }
            }
          }
        }
        &.donut{
          .graph{
            height: 200px;
            position: relative;
            width: 200px;
            .hover;
            .bar {
              border-radius: 50%;
              height: 100%;
              position: absolute;
              width: 100%;
              .hover;
              &.dona{
                z-index: 99;
                width: 150px;
                height: 150px;
                position: absolute;
                background: #fff;
                top: 0;
                left: 0;
                right: 0;
                display: flex;
                margin: 25px auto;
                padding: 25px 0;
                text-align: center;
                align-items: center;
                -webkit-box-shadow: inset 0px 0px 13px 5px rgba(0,0,0,0.37); 
                box-shadow: inset 0px 0px 13px 5px rgba(0,0,0,0.37);
                span{
                  width: 100%;
                }
              }
            }
          }
        }
        &.speedometer{
          .graph{
            transform: rotate(270deg);
            height: 400px;
            position: relative;
            width: 400px;
            clip-path: polygon(50% 0%, 100% 0%, 100% 100%, 50% 100%);
            .hover;
            .bar {
              border-radius: 50%;
              height: 100%;
              position: absolute;
              width: 100%;
              .hover;
              &.dona{
                z-index: 99;
                width: 300px;
                height: 300px;
                position: absolute;
                background: #f0f0f0;
                top: 0;
                left: 0;
                right: 0;
                display: flex;
                text-align: center;
                align-items: center;
                margin: 50px auto;
                padding:  0 0 150px 0 ;
                -webkit-box-shadow: inset 0px 0px 13px 5px rgba(0,0,0,0.37); 
                box-shadow: inset 0px 0px 13px 5px rgba(0,0,0,0.37);
                transform: rotate(90deg);
                -webkit-transform: rotate(90deg);
                -moz-transform: rotate(90deg);
                //position: relative;
                span{
                  width: 100%;
                }
                .needle{
                  position: absolute;
                  top: 0;
                  width: 300px;
                  height: 300px;
                  z-index: -1;
                }
              }
            }
          }
        }

      }
      .shape{
        position: relative;
        svg{
          width: 100%;
          rect{
            width: 100%;
          }
        }
        .text{
          position: absolute;
          display: block;
          padding: 10px;
          width: 100%;
          text-align: center;
          top:0;
        }
        .rectangle{

        }
        .line{
          
        }
        .ellipse{

        }
        .poligon{

        }
      }
      .tabs{
        padding: 20px 10px;
        &.sticky{
          position: fixed;
          width: 31.1%;
          height: 100vh;
          top: 0;
          .hover;
        }
        .options{
          display: block;
          overflow: hidden;
          span, img{
            cursor: pointer;
            width: 50%;
            padding: 8px 20px;
            display: block;
            float: left;
            color: #5A5A5A;
            font-weight: 700;
            font-size: 16px;
            background:#f0f0f0;
            text-align: center;
            .hover;
            &.active{
              .hover;
              background:#e8e8e8;
            }
          }
        }
        .content{
          width: 100%;
          position: relative;
          .tab{
            background:#e8e8e8;
            position: absolute;
            top: 0;
            .hover;
            padding: 20px;
            display: block;
            width: 100%;
            opacity:0;
            z-index: 1;
            &.show{
              z-index: 2;
              .hover;
              opacity: 1;
            }
          }
        }
      }
      //Btn
      &:hover{
        .hover;
        &.inhert{
          border: none;
          padding: 0;
        }
      }
      
    }
    .add-table{
      border-top: 1px solid #ccc;
      display: block;
      position: relative;
      background: #fff;
      text-align: center;
      width: 100%;
      padding: 10px;
      cursor: pointer;
      .hover();
      font-weight: 600;
      color: #5a5a5a;
      &:hover{
        .hover();
        background: #f0f0f0;
      }
    }
    //Styles Custom
    .readOnly{
      input, textarea, select{
        background: transparent;
        padding: 2px;
        font-weight: bolder;
        height: auto;
        font-size: 16px;
        pointer-events: none;
      }
    }
    .currency{
      label{
        text-align: left;
      }
      text-align: right;
    }
    //Styles in Edit Layout
    .layout-wrap, .input-wrap, .element-wrap{
      &:hover{
        .hover;
        .btn-custom{
          .hover;
          opacity: 1;
        }
      }
    }
    
  }
  .public-wrap{
    width: 100%;
    padding: 20px;
    min-height: 100vh;
    overflow-y: auto ;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  //Cart
  .cart-wrap{
    .products{
    }
    .wrap-payment{
      position: relative;
      .data{
        opacity: 0;
        height: 0;
        z-index: 0;
        .hover;
        &.show{
          z-index: 1;
          opacity: 1;
          height: auto;
          .hover;
        }
      }
    }
  }
  //Components
  .inputBad{
    border: 1px solid #ff0000 !important;
  }
  .inputGood{
    border: 1px solid #00ff3a !important;
  }
  .step{
    display: none;
    &.show{
      display: block;
    }
  }
  .choice{
    background: transparent ;
    .hover;
    &.selected{
      .hover;
      background: #ffffff76;
    }
  }
  .preview-calendar{
    width: 100%;
    font-display: 'Montserrat';
    thead{
      box-sizing: border-box;
      tr{
        text-align: center;
        color: #000;
        font-weight: 700;
        th{
          padding: 10px ;
        }
      }
    }
    tbody{
      box-sizing: border-box;
      tr{
        text-align: center;
        width: 100%;
        td{
          font-size: 12px;
          cursor: pointer;
          .hover;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          margin:0 auto;
          font-weight: 500;
          &.td-cal{
            &:hover{
              .hover;
              background: #00000027;
            }
            font-weight: 600;
          }
          &.today{
            background: #0000001b;
          }
          &.disabled{
            opacity: 0.5;
            color: rgb(0, 0, 0);
            pointer-events: none;
          }
          &.selected{
            opacity: 0.5;
            background: #3b3b3b;
            color: #fff;
          }
        }
      }
    }
  }

  .gLanguage{
    overflow: hidden;
    .hover;
    span{
      font-weight: 600;
      opacity: 0.7; 
      &.active{
        .hover;
        opacity: 1;
        font-weight: 800;
        position: relative;
        text-decoration: underline ;
      }
    }
  }
  .notify{
    overflow: hidden;
    display: block;
    display: flex;
    align-items: center;
    .delete{
      height: 100%;
      padding: 5px 0 0 15px;
      opacity: 0.6;
      .hover;
      svg{
        display:block;
        margin:  auto;
        width: 20px;
        height: 20px;
        path{
          fill: #ff0000;
        }
      }
      &:hover{
        .hover;
        opacity: 1;
      }
    }
  }
  .wrap{
    width: 100%;
  }
  .modal-backdrop{
    z-index: -1;
    opacity: 0!important;
  }
  .modal-g{
    overflow-y: auto;
    width: 100%;
    padding: 0!important;
    padding-right: 0!important;
    box-sizing: content-box;
    .modal-dialog {
      width: 100% !important;
      max-width: 1100px !important;
      right: 20px;
      position: absolute;
      margin: 0;
      &.large{
        right: 0;
        left: 0;
        margin: 0 auto;
        max-width: 90vw !important;
        margin: 0 auto;
        right: 0;
        position: relative;
        .modal-content{
          background: #f0f0f0;
        }
      }
      &.middle{
        right: 0;
        left: 0;
        margin: 0 auto;
        max-width: 1000px !important;
        margin: 0 auto;
        right: 0;
        position: relative;
        .modal-content{
          background: #f0f0f0;
        }
      }
      &.small{
        right: 0;
        left: 0;
        margin: 0 auto;
        max-width: 600px !important;
        margin: 0 auto;
        right: 0;
        position: relative;
        .modal-content{
          background: #f0f0f0;
        }
      }
      &.file, &.sms, &.message{
        max-width: 600px !important;
        margin: 0 auto;
        right: 0;
        position: relative;
        .modal-content{
          background: #f0f0f0;
        }
      }
      &.status, &.email{
        margin: 0 auto;
        position: relative;
      }
      &.custom{
        padding: 10px;
        width: 100% !important;
        max-width: 550px !important;
        margin: 0;
        right: 0;
        display: block;
        clear:both;
        .modal-content{
          padding: 30px;
          overflow: hidden;
          height: calc(100vh - 20px) ;
          margin: 0;
          position: relative;
          width: 100%;
          .panel{
            text-align: left;
            max-height: calc(100vh - 150px);
            height: 100%;
            width: 100%;
            overflow-y: auto;
            font-size: 12px;
          }
          .action{
            bottom:30px;
            width: calc(100% - 60px);
            display: block;
            position: absolute;
          }
        }
      }
      &.web-preview{
        max-width: 1200px !important;
        width: 100%;
        margin: 0 auto;
        position: relative;
        .modal-content{
          background: #f0f0f0;
          padding: 40px 10px 10px 10px;
        }
      }
      .modal-content{
        margin: 20px 0;
        border-radius: 0!important;
        padding: 40px;
        border: none;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.45);
        border-radius: 20px;
        
      }
    }
    .modalAll{
      right: 0;
      display: flex;
      padding: 0;
      margin: 0;
      .modal-content{
        margin: 0;
        padding: 0;
        .m-content{
          padding: 40px;
          text-align: center;
          min-height: 100vh;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          justify-content: center;
          align-content: flex-start;
        }
      }
    }
  }
  .modal-default {
    padding: 10px !important;
    .modal-dialog {
      margin: 5% auto;
      width: 100% !important;
      max-width: 1100px !important;
      padding: 0;
      border-radius: 10px;
      background: #f7f7f7;
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.45);
      border-radius: 20px;
      &.delete {
        max-width: 800px !important;
        margin: 50vh auto;
      }
      .modal-content {
        padding: 40px;
        border: 0;
        outline: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        width: 100% !important;
        background: #f7f7f7;
      }
    }
  }
  .btn-g {
    margin: 0;
    border: none;
    font-size: 16px;
    font-weight: bolder;
    line-height: 32px;
    position: relative;
    text-decoration: none!important;
    padding: 6px 25px;
    text-align: center;
    opacity: 0;
    z-index: -1;
    .hover;
    //Show
    &.show{
      opacity: 1;
      z-index: 1;
      .hover;
      }
    //Position
    &.right{
      float: right;
    }
    &.left{
      float: left;
    }
    &.center{
      margin: 0 auto;
      display: block;
    }
    &.gray{
      background: #5a5a5a;
      color: #fff!important;
    }
    &.orange{
      background: #ff6600;
      color: #fff!important;
    }
    &.red{
      background: #f74a4a;
      color: #fff!important;
    }
    &.green{
      background: #2bad30;
      color: #fff!important;
    }
    &.blue{
      background: #006eff;
      color: #fff!important;
    }
    &.transparent{
      background: #00000000;
      color:#fff!important;
    }
    &.venmo{
      background: rgb(43,212,255);
      background: linear-gradient(305deg, rgba(43,212,255,1) 0%, rgba(45,178,255,1) 100%);
      //#2bd4ff #2db2ff
      color:#fff!important;
    }
    &.white{
      background: #fff;
      color: #fff!important;
    }
    &:hover {
      .hover;
      border: 0;
      opacity: 0.8;
    }
  }
  .btn-repeatable{
    position: absolute;
    z-index: 99;
    font-size: 25px;
    right: 0;
    top: 0;
    padding: 0 5px;
    color: #5A5A5A;
    font-weight: bold;
    //opacity: 0;
    .hover;
    &:hover{
      .hover;
    }
  }
  .btn-custom{
    position: absolute;
    z-index: 99;
    font-size: 25px;
    right: 0;
    top: 0;
    padding: 0 5px;
    color: #767676;
    font-weight: bold;
    opacity: 0;
    .hover;
    &:hover{
      color: #000;
      .hover;
    }
  }
  .pagination{
    margin: 20px 0;
    overflow-y: auto ;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    a{
      width: 25px;
      height: 25px;
      font-size: 12px;
      padding: 4px;
      border-radius: 50%;
      color:#000;
      .hover();
      &:hover, &.active{
        .hover();
        background: #000;
        color: #fff;
      }
    }

  }
  //App of type business
  .modal-custom{
    
  }
  //View  
  .infoItem{
    color: #949494;
    margin:0;
    font-size: 12px;
    padding: 0;
    cursor:pointer;
  }
  .status{
    position: relative;
    .line{
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .displayNone{
    display: none;
  }
  .help {
    &:hover {
      width: auto;
      max-width: 200px;
    }
  }
  .btn-fix {
    height: 50px;
    width: 50px;
    right: 0;
    border-radius: 50%;
    overflow: hidden;
    text-align: center;
    background-color: #1E6FD9;
    margin: 20px 20px 20px 15px;
    position: fixed;
    bottom: 0;
    padding: 15px;
    z-index: -2;
    opacity: 0;
    cursor: pointer;
    .shadowBox;
    .hover;
    &.show{
      z-index: 999;
      opacity: 1;
      .hover;
    }
  }
  .clearSearch{
    cursor: pointer;
    position: absolute;
    color: rgb(87, 87, 87);
    transition: all 0.3s ease 0s;
    right: 10px;
    padding: 10px 5px;
    font-size: 20px;
    z-index: 999;
  }
  .callBox {
    height: auto;
    width: 400px;
    right: 0;
    border-radius: 1px;
    overflow: hidden;
    text-align: center;
    .shadowBox;
    margin: 20px 45px 45px 15px;
    position: fixed;
    bottom: 0;
    z-index: -1;
    opacity: 0;
    cursor: pointer;
    .hover;
    &.show{
      z-index: 9999;
      opacity: 1;
      .hover;
    }
  }
  .callViewBox {
    height: 500px;
    width: 400px;
    bottom: 0;
    z-index: 999;
    .hover;
  }
  .callIncomingBox {
    z-index: 1;
    overflow: hidden;
    height: 0;
    width: 400px;
    opacity: 0;
    background: #dfdfdf;
    padding: 0;
    .shadowBox;
    .hover;
    h4{
      padding: 10px 0 0 0;
      font-size: 28px;
      font-weight: 600;
    }
    &.show{
      padding: 10px;
      height: 200px;
      opacity: 1;
      .hover;
    }
  }
  .inputHelp{ 
    position: absolute;
    z-index: -1;
    width: 300px;
    padding: 5px 10px;
    border: 1px solid #5a5a5a;
    background: rgb(175, 174, 174); 
    left: 20px;
    bottom: -2px;
    border-top-left-radius: 15px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 15px;
    opacity: 0;
    overflow: hidden;
    .hover;
    &.show{
      z-index: 99;
      opacity: 1;
      .hover;
    }
  }
  //Button Actions
  .save-btn{
    background: #ff0000;
    svg{
      path{
        fill: #fff;
      }
    }
  }
  .options {
    position: relative;
    margin: 0 auto;
    .col {
      svg, img {
        width: 15px;
        height: 15px;
        margin: 0 auto;
        display: block;
        path {
          fill: #fff;
        }
      }
      width: 36px;
      float: left;
      margin: 3px;
      padding: 10px;
      border-radius: 50%;
      &.action {
        background: #60a80ec2;
        -webkit-transition: all 0.3s ease 0s;
        -moz-transition: all 0.3s ease 0s;
        -ms-transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
        &:hover {
          background: #60a80e;
          -webkit-transition: all 0.3s ease 0s;
          -moz-transition: all 0.3s ease 0s;
          -ms-transition: all 0.3s ease 0s;
          -o-transition: all 0.3s ease 0s;
          transition: all 0.3s ease 0s;
        }
      }
      &.edit {
        background: #00000080;
        -webkit-transition: all 0.3s ease 0s;
        -moz-transition: all 0.3s ease 0s;
        -ms-transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
        &:hover {
          background: #000000b0;
          -webkit-transition: all 0.3s ease 0s;
          -moz-transition: all 0.3s ease 0s;
          -ms-transition: all 0.3s ease 0s;
          -o-transition: all 0.3s ease 0s;
          transition: all 0.3s ease 0s;
        }
      }
      &.delete {
        background: #fc182b;
        -webkit-transition: all 0.3s ease 0s;
        -moz-transition: all 0.3s ease 0s;
        -ms-transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
        &:hover {
          background: #d10516;
          -webkit-transition: all 0.3s ease 0s;
          -moz-transition: all 0.3s ease 0s;
          -ms-transition: all 0.3s ease 0s;
          -o-transition: all 0.3s ease 0s;
          transition: all 0.3s ease 0s;
        }
      }
      &.send {
        background: #3685edf8;
        -webkit-transition: all 0.3s ease 0s;
        -moz-transition: all 0.3s ease 0s;
        -ms-transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
        &:hover {
          background: #0D65D9;
          -webkit-transition: all 0.3s ease 0s;
          -moz-transition: all 0.3s ease 0s;
          -ms-transition: all 0.3s ease 0s;
          -o-transition: all 0.3s ease 0s;
          transition: all 0.3s ease 0s;
        }
      }
      &.history {
        background: #9500ffa8;
        -webkit-transition: all 0.3s ease 0s;
        -moz-transition: all 0.3s ease 0s;
        -ms-transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
        &:hover {
          background: #9500ffe0;
          -webkit-transition: all 0.3s ease 0s;
          -moz-transition: all 0.3s ease 0s;
          -ms-transition: all 0.3s ease 0s;
          -o-transition: all 0.3s ease 0s;
          transition: all 0.3s ease 0s;
        }
      }
      &.archive {
        background: #A67E5B;
        -webkit-transition: all 0.3s ease 0s;
        -moz-transition: all 0.3s ease 0s;
        -ms-transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
        &:hover {
          background: #7c5e44;
          -webkit-transition: all 0.3s ease 0s;
          -moz-transition: all 0.3s ease 0s;
          -ms-transition: all 0.3s ease 0s;
          -o-transition: all 0.3s ease 0s;
          transition: all 0.3s ease 0s;
        }
      }
      &.dashboard {
        background: #009738;
        -webkit-transition: all 0.3s ease 0s;
        -moz-transition: all 0.3s ease 0s;
        -ms-transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
        &:hover {
          background: #007C3E;
          -webkit-transition: all 0.3s ease 0s;
          -moz-transition: all 0.3s ease 0s;
          -ms-transition: all 0.3s ease 0s;
          -o-transition: all 0.3s ease 0s;
          transition: all 0.3s ease 0s;
        }
      }
      &.userCreated {
        background: #ffad15;
        -webkit-transition: all 0.3s ease 0s;
        -moz-transition: all 0.3s ease 0s;
        -ms-transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
        &:hover {
          background: #ffa600;
          -webkit-transition: all 0.3s ease 0s;
          -moz-transition: all 0.3s ease 0s;
          -ms-transition: all 0.3s ease 0s;
          -o-transition: all 0.3s ease 0s;
          transition: all 0.3s ease 0s;
        }
      }
      &.favorite {
        background: #FFD218d9;
        -webkit-transition: all 0.3s ease 0s;
        -moz-transition: all 0.3s ease 0s;
        -ms-transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
        &:hover {
          background: #FFD218;
          -webkit-transition: all 0.3s ease 0s;
          -moz-transition: all 0.3s ease 0s;
          -ms-transition: all 0.3s ease 0s;
          -o-transition: all 0.3s ease 0s;
          transition: all 0.3s ease 0s;
        }
      }
      &.clone {
        background: #0455BFd9;
        -webkit-transition: all 0.3s ease 0s;
        -moz-transition: all 0.3s ease 0s;
        -ms-transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
        &:hover {
          background: #0455BF;
          -webkit-transition: all 0.3s ease 0s;
          -moz-transition: all 0.3s ease 0s;
          -ms-transition: all 0.3s ease 0s;
          -o-transition: all 0.3s ease 0s;
          transition: all 0.3s ease 0s;
        }
      }
      &.blank {
        background: #ff4400d7;
        -webkit-transition: all 0.3s ease 0s;
        -moz-transition: all 0.3s ease 0s;
        -ms-transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
        &:hover {
          background: #ff4400;
          -webkit-transition: all 0.3s ease 0s;
          -moz-transition: all 0.3s ease 0s;
          -ms-transition: all 0.3s ease 0s;
          -o-transition: all 0.3s ease 0s;
          transition: all 0.3s ease 0s;
        }
      }
    }
  }
  //Icons Editor
  .editor-before(){
    content:'';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    right: 0;
  }
  .trumbowyg-viewHTML-button{
    &::before{
      .editor-before;
      background: url('./assets/icons/editor_html.svg') no-repeat center center;
    }
  }
  .trumbowyg-undo-button{
    &::before{
      background: url('./assets/icons/editor_undo.svg') no-repeat center center;
      .editor-before;
    }
  }
  .trumbowyg-redo-button{
    &::before{
      background: url('./assets/icons/editor_redo.svg') no-repeat center center;
      .editor-before;
    }
  }
  .trumbowyg-formatting-button{
    &::before{
      background: url('./assets/icons/editor_formatting.svg') no-repeat center center;
      .editor-before;
    }
  }
  .trumbowyg-strong-button{
    &::before{
      background: url('./assets/icons/editor_strong.svg') no-repeat center center;
      .editor-before;
    }
  }
  .trumbowyg-em-button{
    &::before{
      background: url('./assets/icons/editor_emphasis.svg') no-repeat center center;
      .editor-before;
    }
  }
  .trumbowyg-del-button{
    &::before{
      background: url('./assets/icons/editor_deleted.svg') no-repeat center center;
      .editor-before;
    }
  }
  .trumbowyg-superscript-button{
    &::before{
      background: url('./assets/icons/editor_superscript.svg') no-repeat center center;
      .editor-before;
    }
  }
  .trumbowyg-subscript-button{
    &::before{
      background: url('./assets/icons/editor_subscript.svg') no-repeat center center;
      .editor-before;
    }
  }
  .trumbowyg-link-button{
    &::before{
      background: url('./assets/icons/editor_link.svg') no-repeat center center;
      .editor-before;
    }
  }
  .trumbowyg-insertImage-button{
    &::before{
      background: url('./assets/icons/editor_image.svg') no-repeat center center;
      .editor-before;
    }
  }
  .trumbowyg-justifyLeft-button{
    &::before{
      background: url('./assets/icons/editor_left.svg') no-repeat center center;
      .editor-before;
    }
  }
  .trumbowyg-justifyCenter-button{
    &::before{
      background: url('./assets/icons/editor_center.svg') no-repeat center center;
      .editor-before;
    }
  }
  .trumbowyg-justifyRight-button{
    &::before{
      background: url('./assets/icons/editor_right.svg') no-repeat center center;
      .editor-before;
    }
  }
  .trumbowyg-justifyFull-button{
    &::before{
      background: url('./assets/icons/editor_justify.svg') no-repeat center center;
      .editor-before;
    }
  }
  .trumbowyg-unorderedList-button{
    &::before{
      background: url('./assets/icons/editor_unordered.svg') no-repeat center center;
      .editor-before;
    }
  }
  .trumbowyg-orderedList-button{
    &::before{
      background: url('./assets/icons/editor_ordered.svg') no-repeat center center;
      .editor-before;
    }
  }
  .trumbowyg-horizontalRule-button{
    &::before{
      background: url('./assets/icons/editor_horizontal.svg') no-repeat center center;
      .editor-before;
    }
  }
  .trumbowyg-removeformat-button{
    &::before{
      background: url('./assets/icons/editor_remove.svg') no-repeat center center;
      .editor-before;
    }
  }
  .trumbowyg-fullscreen-button{
    &::before{
      background: url('./assets/icons/editor_full.svg') no-repeat center center;
      .editor-before;
    }
  }
  //Loading
  .loading{
    width: 100%;
    height: 100vh;
    z-index: -999;
    position: fixed;
    background: #000000b1;
    align-items: center;
    opacity: 0;
    display: flex;
    .hover;
    &.show {
      z-index: 9999;
      opacity: 1;
      .hover;
    }
    .loader {
      display: block;
      position: relative;
      width: 100px;
      height: 100px;
      margin: 0 auto;
      border-radius: 50%;
      border: 3px solid transparent;
      border-top-color: #ffffff;
      -webkit-animation: spin 2s linear infinite;
      animation: spin 2s linear infinite;
      &:before {
        content: "";
        position: absolute;
        top: 5px;
        left: 5px;
        right: 5px;
        bottom: 5px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: #afafaf;
        -webkit-animation: spin 3s linear infinite;
        animation: spin 3s linear infinite;
      }
      &:after {
          content: "";
          position: absolute;
          top: 15px;
          left: 15px;
          right: 15px;
          bottom: 15px;
          border-radius: 50%;
          border: 3px solid transparent;
          border-top-color: #6d6d6d;
          -webkit-animation: spin 1.5s linear infinite;
          animation: spin 1.5s linear infinite;
      }
    }
  }
  @-webkit-keyframes spin {
      0%   {
          -webkit-transform: rotate(0deg);
          -ms-transform: rotate(0deg);
          transform: rotate(0deg);
      }
      100% {
          -webkit-transform: rotate(360deg);
          -ms-transform: rotate(360deg);
          transform: rotate(360deg);
      }
  }
  @keyframes spin {
      0%   {
          -webkit-transform: rotate(0deg);
          -ms-transform: rotate(0deg);
          transform: rotate(0deg);
      }
      100% {
          -webkit-transform: rotate(360deg);
          -ms-transform: rotate(360deg);
          transform: rotate(360deg);
      }
  }
  .noScroll{
    overflow: hidden;
    
  }
  //variants
  .variants{
    background:#D9D9D9; padding:20px; overflow:hidden; display:block; cursor:pointer;
    .hover;
    &:hover{
      border-radius: 10px;
      background:#cecece;
      .hover;
      //-webkit-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.15); 
      //box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.15);
    }
    &.active{
      .hover;
      box-shadow: 0px 0px 9px 1px rgba(0,208,255,0.87);
      -webkit-box-shadow: 0px 0px 9px 1px rgba(0,208,255,0.87);
      -moz-box-shadow: 0px 0px 9px 1px rgba(0,208,255,0.87);
    }
  }
  //Especils Clases
  .quiz{
    .input-wrap{
      margin: 10px 0;
      input, select, textarea {
        //all: unset;
        &:hover{
          //all: unset;
        }
      }
      .label{
        background: #fff;
        position: absolute;
        top: -5px;
        left: 16px;
        display: block;
        width: auto;
        padding: 0 5px;
        z-index: 99;
      }
      label{
        font-size: 12px!important;
      }
      .check-wrap{
        border: 1.5px solid #0d366f;
        overflow: hidden;
        border-radius: 5px;
        padding: 0 10px;
      }
      .check-wrap{
        overflow: hidden;
        .envelop{
          width: 200px;
          //height: 60px;
          display: block;
          float: left;
        }
        .input{
          width: 30px;
          height: 30px;
          margin: 20px 0 0 0;
          display: block;
          float: left;
        }
        .text{ 
          width: calc( 100% - 30px);
          display: block;
          float: left;
          padding: 20px 0 20px 8px;
        }
      }
      input, select, textarea {
        box-sizing: border-box;
        width: 100%;
        background: #fff;
        border: 1.5px solid #0d366f;
        height: 50px;
        padding: 10px;
        border-radius: 5px;
        color: #0d366f;
        .hover();
        &:hover{
          .hover();
          color: #0d366f;
          border-radius: 5px;
          box-sizing: border-box;
          width: 100%;
          background: #fff;
          border: 1.5px solid #0d366f;
          height: 50px;
          padding: 10px;
        }
      }
    }
  }
  .fadeIn{
    -webkit-animation: fadeIn 0.5s both; animation: fadeIn 0.5s both;
    &.s1{ -webkit-animation: fadeIn 1s both; animation: fadeIn 1s both; }
    &.s1-5{ -webkit-animation: fadeIn 1.5s both; animation: fadeIn 1.5s both; }
    &.s2{ -webkit-animation: fadeIn 2s both; animation: fadeIn 2s both; }
    &.s3{ -webkit-animation: fadeIn 3s both; animation: fadeIn 3s both; }
    &.s4{ -webkit-animation: fadeIn 4s both; animation: fadeIn 4s both; }
    &.s5{ -webkit-animation: fadeIn 5s both; animation: fadeIn 5s both; }
  }
  @keyframes fadeIn {
    0% { opacity: 0; }
    70% { opacity: 0.4; }
    100% { opacity: 1; }
  }
  .fadeOut{
    -webkit-animation: fadeOut 0.5s both; animation: fadeOut 0.5s both;
    &.s1{ -webkit-animation: fadeOut 1s both; animation: fadeOut 1s both; }
    &.s1-5{ -webkit-animation: fadeOut 1.5s both; animation: fadeOut 1.5s both; }
    &.s2{ -webkit-animation: fadeOut 2s both; animation: fadeOut 2s both; }
    &.s3{ -webkit-animation: fadeOut 3s both; animation: fadeOut 3s both; }
    &.s4{ -webkit-animation: fadeOut 4s both; animation: fadeOut 4s both; }
    &.s5{ -webkit-animation: fadeOut 5s both; animation: fadeOut 5s both; }
  }
  //Smart Layout Templates
  .display-p1{
    background: #0000000a;
    width: 100%;
    height: auto;
    overflow: hidden;
    .block{
      .title{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 27px;
        color: #000000;
      }
      .subtitle{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: #000000;
      }
      p{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
      }
    }
  }

  /*.modal:nth-of-type(even) {
    z-index: 1052 !important;
}
  .modal-backdrop.show:nth-of-type(even) {
      z-index: 1051 !important;
  }*/
  //Mesponsive
  @media (max-width: 992px) {}
  @media (max-width: 768px) {
    .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12{
      padding: 10px 0;
    }
    h1{
      font-size: 30px;
    }
    h2{
      font-size: 24px;
    }
    h3{
      font-size: 20px;
    }
    h4{
      font-size: 18px;
    }
    h5{
      font-size: 16px;
    }
    .col-text{
      padding: 0;
    }
    .paper{
      overflow: hidden;
      padding: 0 20px;
      width: 100vw;
      left: 0;
      .header{
        max-width: 100vw;
        .absoluteCenterFlex;
        height: auto;
        padding: 10px 0 0px 0;
        .menu{
          width: 50px;
        }
        .home-bar{
          .absoluteCenterFlex;
          width: 100%;
          img{
            min-height: 100px;
          }
          a{
            padding: 0 10px;
          }
          i{
            display:none;
          }
        }
        .control-bar{
          width: auto;
          .absoluteCenterFlex;
          justify-content: flex-end;
          flex-wrap: nowrap;
          p, .calls{
            display: none;
          }
          .notifications{
            padding: 10px;
          }
        }
        .searchContact{
          position: absolute;
          bottom: 0;
          padding: 5px 0px;
        }
        
        //display:none;
        h6{
          font-size: 16px;
        }
      } 
      .dash{
        width: 100%;
      }
      .footer{
        font-size: 10px;
      }
    }
    .smart-wrap{
      //Styles Layout Web
      .header{
        width: 100%!important;
        position:relative!important;
        
      }

      //
      .input-wrap{
        label{
          font-size: 10px;
        }
        input, textarea, select{
          font-size: 12px;
        }
      }
      .element-wrap, .module-wrap{
        //Tables
        .table{
          thead{
            th{
              font-size: 12px;
            }
          }
          tbody{
            tr{
              td{
                font-size: 10px;
                &.options-td{
                  padding: 10px !important;
                  width: 160px;
                  .options {
                    .col {
                      width: 25px;
                      height: 25px;
                      padding: 7px;
                      svg {
                        width: 10px;
                        height: 10px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .input-wrap, .element-wrap, .module-wrap, .layout-wrap{
        width: 100%;
        
      }
      //Styles Custom
      .readOnly{
        input, textarea, select{
          font-size: 12px;
        }
      }
    }
    .modal-g{
      .modal-dialog {
        right: 0;
        .modal-content{
          margin: 0;
          padding: 20px;
          .m-content{
            .wrap-button{
              display: flex;
              justify-content: flex-end;
              align-items: center;
              width: 100%;
              span{
                .absoluteCenterFlex;
              }
              .btn-g{
                margin: 0 5px !important;
              }
            }
          }
        }
      }
    }
    .modal-default {
      .modal-dialog {
        margin: 5% auto;
        padding: 20px;
      }
    }
    .btn-g {
      font-size: 12px;
      line-height: 22px;
      padding: 6px 20px;
    }
    .questionnaires-wrap{
      .header{
        margin: 0 0 20px 0;
        justify-content: center;
        h2{
          display:none;
        }
        .button-q{
          a{
            width: calc(50% - 10px);
            margin: 0 5px!important;
          }
        }
      }
    }
    .quoteComponent{
      display: flex!important;;
      flex-wrap: wrap;
      flex-direction: column-reverse;
      align-items: center;
    }
    
  }
  @media (max-width: 520px) {
    .smart-wrap{
      width: 100%;
    }
    .clearResponsive{
      bottom:0!important;
      top:0!important;
      position:relative!important;
    }
    .img-resp{
      width: 200px;
      margin:0 auto;
    }
    p{
      font-size: 14px;
    }
    h4{
      font-size: 20px;
    }
    .calendar-wrap{
      background: #fff;
      .col-calendar{
        width: 340px;
        display: block;
        margin:0 auto;
        padding:0;
      }
      .col-content{
        width: 100%;
        box-sizing: border-box;
        padding: 0!important;
        .disabled{
          opacity: 0.5;
          pointer-events: none;
        }
        h4{
          text-align: center;
          margin: 20px 0 0 0;
          color: #5A5A5A;
          font-weight: 600;
        }
        .meet{
          border: 1px solid #0059ff;
          width: 100%;
          float: left;
          margin: 10px 0;
          text-align: center;
          color: #5A5A5A;
          font-weight: 600;
          a{
            padding: 10px;
            display: block;
            width: 100%;
          }
        }
      }
    }
    .container-in{
      padding: 25px 20px;
    }
  }
  @media (max-width: 420px) {
  }
</style>
