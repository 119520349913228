<template>
  <div>
    <pre>{{ valueString }}</pre>
  </div>
</template>
<script>
const props = {
  title: {
    type: String
  },
  value: {
    required: true
  }
};
export default {
  name: "paper-displayer",
  props,
  computed: {
    valueString() {
      return JSON.stringify(this.value, null, 2);
    }
  }
};
</script>
<style scoped>
  pre {
    text-align: start;
    font-family: 'Montserrat';
    font-size: 11px;
  }
</style>
