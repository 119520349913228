/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const smd_deactivateRecord = /* GraphQL */ `
  mutation Smd_deactivateRecord($PK: String, $SK: String!) {
    smd_deactivateRecord(PK: $PK, SK: $SK) {
      PK
      SK
      active
      statusCode
      message
      userMessage
    }
  }
`;
export const smd_setItemState = /* GraphQL */ `
  mutation Smd_setItemState($PK: String!, $SK: String!, $state: String!) {
    smd_setItemState(PK: $PK, SK: $SK, state: $state) {
      data
      additionalData
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_activateRecord = /* GraphQL */ `
  mutation Smd_activateRecord($PK: String, $SK: String!) {
    smd_activateRecord(PK: $PK, SK: $SK) {
      PK
      SK
      active
      statusCode
      message
      userMessage
    }
  }
`;
export const updateMetaRecord = /* GraphQL */ `
  mutation UpdateMetaRecord($input: MetaRecordInput!) {
    updateMetaRecord(input: $input) {
      PK
      SK
      GSP1PK1
      GSP1SK1
      GSP3PK1
      GSP3SK1
      entityType
      name
      description
      fieldsMeta
      metafieldName
      attributeName
      placeHolder
      inputType
      valueOptions
      useType
      help
      icon
      color
      permits
      attributes {
        attributeName
        attributeType
        inputType
        attributeValue
      }
      strAtt
      createdAt
      updatedAt
      active
      createdBy
      notes
      width
      height
      value
      multiple
      updateAt
    }
  }
`;
export const smd_updateRecord = /* GraphQL */ `
  mutation Smd_updateRecord($input: smd_recordInput!) {
    smd_updateRecord(input: $input) {
      PK
      SK
      GSP1PK1
      GSP1SK1
      GSP2PK1
      GSP2SK1
      GSP3PK1
      GSP3SK1
      GSP4PK1
      GSP4SK1
      entity {
        PK
        SK
      }
      parent {
        PK
        SK
      }
      form {
        PK
        SK
      }
      isMeta
      createdAt
      updatedAt
      active
      createdBy
      dataObj
      attributes {
        attributeName
        attributeType
        inputType
        attributeValue
      }
      strAtt
      elements
      groupsCanRead
      groupsCanEdit
      usersCanEdit
      usersCanRead
      responsibles
      version
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
      delete_entityType
      readOnly
      friendlyId
      entityName
      shortEntity
    }
  }
`;
export const smd_updateSmartRecord = /* GraphQL */ `
  mutation Smd_updateSmartRecord($input: smd_recordInput!) {
    smd_updateSmartRecord(input: $input) {
      PK
      data
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_updateSmartAppointment = /* GraphQL */ `
  mutation Smd_updateSmartAppointment($input: smd_recordInput!) {
    smd_updateSmartAppointment(input: $input) {
      PK
      data
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_attributesUpdate = /* GraphQL */ `
  mutation Smd_attributesUpdate($input: smd_recordInput!) {
    smd_attributesUpdate(input: $input) {
      PK
      SK
      GSP1PK1
      GSP1SK1
      GSP2PK1
      GSP2SK1
      GSP3PK1
      GSP3SK1
      GSP4PK1
      GSP4SK1
      entity {
        PK
        SK
      }
      parent {
        PK
        SK
      }
      form {
        PK
        SK
      }
      isMeta
      createdAt
      updatedAt
      active
      createdBy
      dataObj
      attributes {
        attributeName
        attributeType
        inputType
        attributeValue
      }
      strAtt
      elements
      groupsCanRead
      groupsCanEdit
      usersCanEdit
      usersCanRead
      responsibles
      version
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
      delete_entityType
      readOnly
      friendlyId
      entityName
      shortEntity
    }
  }
`;
export const smd_updateSmartLayout = /* GraphQL */ `
  mutation Smd_updateSmartLayout($input: smd_recordInput!) {
    smd_updateSmartLayout(input: $input) {
      PK
      SK
      GSP1PK1
      GSP1SK1
      GSP2PK1
      GSP2SK1
      GSP3PK1
      GSP3SK1
      GSP4PK1
      GSP4SK1
      entity {
        PK
        SK
      }
      parent {
        PK
        SK
      }
      form {
        PK
        SK
      }
      isMeta
      createdAt
      updatedAt
      active
      createdBy
      dataObj
      attributes {
        attributeName
        attributeType
        inputType
        attributeValue
      }
      strAtt
      elements
      groupsCanRead
      groupsCanEdit
      usersCanEdit
      usersCanRead
      responsibles
      version
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
      delete_entityType
      readOnly
      friendlyId
      entityName
      shortEntity
    }
  }
`;
export const smd_updateRecord1 = /* GraphQL */ `
  mutation Smd_updateRecord1($input: smd_recordInput!) {
    smd_updateRecord1(input: $input) {
      PK
      SK
      GSP1PK1
      GSP1SK1
      GSP2PK1
      GSP2SK1
      GSP3PK1
      GSP3SK1
      GSP4PK1
      GSP4SK1
      entity {
        PK
        SK
      }
      parent {
        PK
        SK
      }
      form {
        PK
        SK
      }
      isMeta
      createdAt
      updatedAt
      active
      createdBy
      dataObj
      attributes {
        attributeName
        attributeType
        inputType
        attributeValue
      }
      strAtt
      elements
      groupsCanRead
      groupsCanEdit
      usersCanEdit
      usersCanRead
      responsibles
      version
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
      delete_entityType
      readOnly
      friendlyId
      entityName
      shortEntity
    }
  }
`;
export const updateMetaField = /* GraphQL */ `
  mutation UpdateMetaField($input: MetaFieldInput!) {
    updateMetaField(input: $input) {
      PK
      SK
      entityType
      shortEntity
      description
      metafieldName
      metafieldLabel
      attributeName
      placeHolder
      inputType
      valueOptions
      metaJSON
      md
      useType
      help
      displayFormat
      icon
      color
      permits
      createdAt
      updatedAt
      active
      createdBy
      strAtt
      belongsToEntity
      entityThatApply
      groupsCanRead
      groupsCanEdit
      usersCanRead
      usersCanEdit
      notes
      updateAt
    }
  }
`;
export const updateMetaForm = /* GraphQL */ `
  mutation UpdateMetaForm($input: MetaFormInput!) {
    updateMetaForm(input: $input) {
      PK
      SK
      entityType
      shortEntity
      name
      help
      description
      fieldsMeta
      sections {
        no
        label
      }
      active
      notes
      icon
      color
      permits
      metaEntityType
    }
  }
`;
export const updateMetaForm2 = /* GraphQL */ `
  mutation UpdateMetaForm2($input: MetaFormInput!) {
    updateMetaForm2(input: $input) {
      PK
      SK
      entityType
      shortEntity
      name
      help
      description
      fieldsMeta
      sections {
        no
        label
      }
      active
      notes
      icon
      color
      permits
      metaEntityType
    }
  }
`;
export const createEmailTemplate = /* GraphQL */ `
  mutation CreateEmailTemplate($input: emailTemplateInput!) {
    createEmailTemplate(input: $input) {
      statusCode
      message
      userMessage
    }
  }
`;
export const createLead = /* GraphQL */ `
  mutation CreateLead($input: leadInput!) {
    createLead(input: $input) {
      statusCode
      message
      userMessage
    }
  }
`;
export const createBasicLead = /* GraphQL */ `
  mutation CreateBasicLead($orgPK: String, $data: String!, $originURL: String) {
    createBasicLead(orgPK: $orgPK, data: $data, originURL: $originURL) {
      data
      smd_responseP
    }
  }
`;
export const smd_customerSignUp = /* GraphQL */ `
  mutation Smd_customerSignUp(
    $orgPK: String
    $data: String!
    $originURL: String
  ) {
    smd_customerSignUp(orgPK: $orgPK, data: $data, originURL: $originURL) {
      data
      additionalData
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const createResource = /* GraphQL */ `
  mutation CreateResource($input: resourceInput!) {
    createResource(input: $input) {
      smName
      description
      timeZone
      regulaHours
      orgRegularHours
      holidays
      entityType
    }
  }
`;
export const sendEmail = /* GraphQL */ `
  mutation SendEmail($input: emailInput) {
    sendEmail(input: $input) {
      status
      message
    }
  }
`;
export const smd_getUploadURL = /* GraphQL */ `
  mutation Smd_getUploadURL($fileName: String) {
    smd_getUploadURL(fileName: $fileName) {
      URL
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_addUser = /* GraphQL */ `
  mutation Smd_addUser(
    $userName: String
    $userEmail: String
    $userOrg: String
  ) {
    smd_addUser(userName: $userName, userEmail: $userEmail, userOrg: $userOrg) {
      data
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_createUserFromEmail = /* GraphQL */ `
  mutation Smd_createUserFromEmail($emailPK: String, $emailSK: String) {
    smd_createUserFromEmail(emailPK: $emailPK, emailSK: $emailSK) {
      data
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_createInternalUser = /* GraphQL */ `
  mutation Smd_createInternalUser($userPK: String, $userSK: String) {
    smd_createInternalUser(userPK: $userPK, userSK: $userSK) {
      data
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_createInternalUser2 = /* GraphQL */ `
  mutation Smd_createInternalUser2($userPK: String, $userSK: String) {
    smd_createInternalUser2(userPK: $userPK, userSK: $userSK) {
      data
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_setUserPassword = /* GraphQL */ `
  mutation Smd_setUserPassword($userName: String, $newPassword: String) {
    smd_setUserPassword(userName: $userName, newPassword: $newPassword) {
      data
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_createSystemRecord = /* GraphQL */ `
  mutation Smd_createSystemRecord(
    $SK: String!
    $attributes: [smd_attributeInput]
  ) {
    smd_createSystemRecord(SK: $SK, attributes: $attributes) {
      PK
      SK
      GSP1PK1
      GSP1SK1
      GSP2PK1
      GSP2SK1
      GSP3PK1
      GSP3SK1
      GSP4PK1
      GSP4SK1
      entity {
        PK
        SK
      }
      parent {
        PK
        SK
      }
      form {
        PK
        SK
      }
      isMeta
      createdAt
      updatedAt
      active
      createdBy
      dataObj
      attributes {
        attributeName
        attributeType
        inputType
        attributeValue
      }
      strAtt
      elements
      groupsCanRead
      groupsCanEdit
      usersCanEdit
      usersCanRead
      responsibles
      version
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
      delete_entityType
      readOnly
      friendlyId
      entityName
      shortEntity
    }
  }
`;
export const smd_sendSMS = /* GraphQL */ `
  mutation Smd_sendSMS(
    $phonePK: String!
    $phoneSK: String!
    $message: String!
    $contactSK: String
  ) {
    smd_sendSMS(
      phonePK: $phonePK
      phoneSK: $phoneSK
      message: $message
      contactSK: $contactSK
    ) {
      data
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_sendEmail2 = /* GraphQL */ `
  mutation Smd_sendEmail2($to: String!, $subject: String, $bodyHTML: String!) {
    smd_sendEmail2(to: $to, subject: $subject, bodyHTML: $bodyHTML) {
      statusCode
      message
      userMessage
      stack
      notifyme
      popAlert
    }
  }
`;
export const smd_sendAndSaveEmail = /* GraphQL */ `
  mutation Smd_sendAndSaveEmail(
    $orgPK: String
    $GSP1PK1: String
    $to: String!
    $subject: String
    $bodyHTML: String!
  ) {
    smd_sendAndSaveEmail(
      orgPK: $orgPK
      GSP1PK1: $GSP1PK1
      to: $to
      subject: $subject
      bodyHTML: $bodyHTML
    ) {
      statusCode
      message
      userMessage
      stack
      notifyme
      popAlert
    }
  }
`;
export const smd_executeBackendFunctions = /* GraphQL */ `
  mutation Smd_executeBackendFunctions(
    $PK: String!
    $SK: String!
    $formula: String
  ) {
    smd_executeBackendFunctions(PK: $PK, SK: $SK, formula: $formula) {
      statusCode
      message
      userMessage
      stack
      notifyme
      popAlert
    }
  }
`;
export const smd_addToFavorites = /* GraphQL */ `
  mutation Smd_addToFavorites($PK: String!, $SK: String!) {
    smd_addToFavorites(PK: $PK, SK: $SK) {
      statusCode
      message
      userMessage
      stack
      notifyme
      popAlert
    }
  }
`;
export const smd_cloneSmartRecord = /* GraphQL */ `
  mutation Smd_cloneSmartRecord($PK: String!, $SK: String!) {
    smd_cloneSmartRecord(PK: $PK, SK: $SK) {
      PK
      data
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_adminTools = /* GraphQL */ `
  mutation Smd_adminTools($operation: String, $PK: String, $params: String) {
    smd_adminTools(operation: $operation, PK: $PK, params: $params) {
      statusCode
      message
      userMessage
      stack
      notifyme
      popAlert
    }
  }
`;
export const smd_addToCart = /* GraphQL */ `
  mutation Smd_addToCart($PK: String, $SK: String, $parameters: String) {
    smd_addToCart(PK: $PK, SK: $SK, parameters: $parameters) {
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
      data
    }
  }
`;
export const smd_updateAttributes = /* GraphQL */ `
  mutation Smd_updateAttributes($PK: String, $SK: String, $attributes: String) {
    smd_updateAttributes(PK: $PK, SK: $SK, attributes: $attributes) {
      PK
      data
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_processPayment = /* GraphQL */ `
  mutation Smd_processPayment(
    $orgPK: String
    $user: String
    $cartItems: String
    $totalCharge: String
    $paymentParameters: String
    $link: String
  ) {
    smd_processPayment(
      orgPK: $orgPK
      user: $user
      cartItems: $cartItems
      totalCharge: $totalCharge
      paymentParameters: $paymentParameters
      link: $link
    ) {
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
      data
    }
  }
`;
export const smd_savePaymentForm = /* GraphQL */ `
  mutation Smd_savePaymentForm(
    $orgPK: String
    $user: String
    $paymentParameters: String
  ) {
    smd_savePaymentForm(
      orgPK: $orgPK
      user: $user
      paymentParameters: $paymentParameters
    ) {
      PK
      data
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_savePaymentFormContact = /* GraphQL */ `
  mutation Smd_savePaymentFormContact(
    $orgPK: String
    $contactSK: String
    $user: String
    $paymentParameters: String
  ) {
    smd_savePaymentFormContact(
      orgPK: $orgPK
      contactSK: $contactSK
      user: $user
      paymentParameters: $paymentParameters
    ) {
      PK
      data
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_recurrentCharge = /* GraphQL */ `
  mutation Smd_recurrentCharge($billPK: String, $paymentMode: String) {
    smd_recurrentCharge(billPK: $billPK, paymentMode: $paymentMode) {
      PK
      data
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_clientSignup = /* GraphQL */ `
  mutation Smd_clientSignup(
    $email: String
    $password: String
    $contactPK: String
    $contactSK: String
    $firstName: String
    $lastName: String
    $phoneNumber: String
  ) {
    smd_clientSignup(
      email: $email
      password: $password
      contactPK: $contactPK
      contactSK: $contactSK
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
    ) {
      PK
      data
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const importfromDash2017 = /* GraphQL */ `
  mutation ImportfromDash2017 {
    importfromDash2017 {
      PK
      data
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const updateItems2017 = /* GraphQL */ `
  mutation UpdateItems2017 {
    updateItems2017 {
      PK
      data
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_unsubscribe = /* GraphQL */ `
  mutation Smd_unsubscribe(
    $itemPK: String
    $itemSK: String
    $contactSK: String
  ) {
    smd_unsubscribe(itemPK: $itemPK, itemSK: $itemSK, contactSK: $contactSK) {
      PK
      data
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_notify = /* GraphQL */ `
  mutation Smd_notify($itemPK: String, $itemSK: String) {
    smd_notify(itemPK: $itemPK, itemSK: $itemSK) {
      PK
      data
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_updateSmartQuiz = /* GraphQL */ `
  mutation Smd_updateSmartQuiz($input: smd_recordInput!) {
    smd_updateSmartQuiz(input: $input) {
      PK
      data
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_updateSmartQuiz1 = /* GraphQL */ `
  mutation Smd_updateSmartQuiz1($input: smd_recordInput!, $action: String) {
    smd_updateSmartQuiz1(input: $input, action: $action) {
      PK
      data
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_createLead2 = /* GraphQL */ `
  mutation Smd_createLead2($itemPK: String, $itemSK: String) {
    smd_createLead2(itemPK: $itemPK, itemSK: $itemSK) {
      PK
      data
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const tmp_updateindex6_7 = /* GraphQL */ `
  mutation Tmp_updateindex6_7(
    $orgPK: String
    $lastEvaluatedkey: String
    $limit: Int
  ) {
    tmp_updateindex6_7(
      orgPK: $orgPK
      lastEvaluatedkey: $lastEvaluatedkey
      limit: $limit
    ) {
      PK
      data
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_mutationOperation = /* GraphQL */ `
  mutation Smd_mutationOperation(
    $orgPK: String
    $templateSK: String
    $item: String
    $operation: String
  ) {
    smd_mutationOperation(
      orgPK: $orgPK
      templateSK: $templateSK
      item: $item
      operation: $operation
    ) {
      data
      additionalData
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_generateDocAdobeApi = /* GraphQL */ `
  mutation Smd_generateDocAdobeApi(
    $orgPK: String
    $GSP1PK1: String
    $templateSK: String
    $attributes: String
    $inputJSON: String
  ) {
    smd_generateDocAdobeApi(
      orgPK: $orgPK
      GSP1PK1: $GSP1PK1
      templateSK: $templateSK
      attributes: $attributes
      inputJSON: $inputJSON
    ) {
      data
      additionalData
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_sendEmailCampaign = /* GraphQL */ `
  mutation Smd_sendEmailCampaign(
    $orgPK: String
    $type: String
    $itemPK: String
    $itemSK: String
  ) {
    smd_sendEmailCampaign(
      orgPK: $orgPK
      type: $type
      itemPK: $itemPK
      itemSK: $itemSK
    ) {
      data
      additionalData
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_backendCustomMutation = /* GraphQL */ `
  mutation Smd_backendCustomMutation($items: String, $operation: String) {
    smd_backendCustomMutation(items: $items, operation: $operation) {
      data
      additionalData
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_updatePassword = /* GraphQL */ `
  mutation Smd_updatePassword(
    $PK: String
    $userName: String
    $email: String
    $pass: String
    $code: String
  ) {
    smd_updatePassword(
      PK: $PK
      userName: $userName
      email: $email
      pass: $pass
      code: $code
    ) {
      data
      additionalData
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_sendResetPassword = /* GraphQL */ `
  mutation Smd_sendResetPassword(
    $email: String
    $userName: String
    $PK: String
  ) {
    smd_sendResetPassword(email: $email, userName: $userName, PK: $PK) {
      data
      additionalData
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_sendCodeVerifyEmail = /* GraphQL */ `
  mutation Smd_sendCodeVerifyEmail($data: String, $email: String, $PK: String) {
    smd_sendCodeVerifyEmail(data: $data, email: $email, PK: $PK) {
      data
      additionalData
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_verifyEmail = /* GraphQL */ `
  mutation Smd_verifyEmail(
    $data: String
    $email: String
    $PK: String
    $code: String
  ) {
    smd_verifyEmail(data: $data, email: $email, PK: $PK, code: $code) {
      data
      additionalData
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_changePassword = /* GraphQL */ `
  mutation Smd_changePassword($PK: String, $email: String, $password: String) {
    smd_changePassword(PK: $PK, email: $email, password: $password) {
      data
      additionalData
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_createCognitoWithPassword = /* GraphQL */ `
  mutation Smd_createCognitoWithPassword($PK: String, $data: String) {
    smd_createCognitoWithPassword(PK: $PK, data: $data) {
      data
      additionalData
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_stepOperation = /* GraphQL */ `
  mutation Smd_stepOperation($PK: String, $data: String, $operation: String) {
    smd_stepOperation(PK: $PK, data: $data, operation: $operation) {
      data
      additionalData
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
