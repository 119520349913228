<template>
  <a style="cursor:pointer; " :class="'btn-g show '+ '  ' +position" :style="'background:'+organizationPalette[1]+'!important;color:#fff!important;'" >

    {{text}}
    <slot> </slot>
  </a>
</template>
<script>
import Vuex from 'vuex'
export default {
  props:{
    color:{
      type: String,
      default: 'gray'
    },
    text:{
      type: String,
      default: 'Main Text'
    },
    position:{
      type: String,
      default: ''
    },
  },
  computed: {
    ...Vuex.mapState(['organizationPalette'])
  },
}
</script>
