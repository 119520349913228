import { createRouter, createWebHistory } from 'vue-router'


const router = createRouter({
  
  history: createWebHistory(process.env.BASE_URL),
  routes:[
    // Auth
    {
      path: '/smartdash',
      name: 'SmartDash',
      component: () => import('../views/auth/Login.vue')
    },
    {
      path: '/forgot',
      name: 'Forgot',
      component: () => import('../views/auth/Forgot.vue')
    },
    // Auth Customer
    {
      path: '/dashCustomer',
      name: 'dashCustomer',
      component: () => import('../views/authCustomer/Login.vue')
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('../views/others/Register.vue')
    },
    {
      path: '/sales',
      name: 'sales',
      component: () => import('../views/others/Sale.vue')
    },
    //Home
    {
      path: '/',
      name: 'Home',
      component: () => import('../views/WebSite.vue')
    },
    {
      path: '/website',
      name: 'website',
      component: () => import('../views/WebSiteV2.vue')
    },
    {
      path: '/checkout',
      name: 'Checkout',
      component: () => import('../views/payments/Checkout.vue')
    },
    {
      path: '/checkout1',
      name: 'Checkou1',
      component: () => import('../views/payments/Checkout1.vue')
    },
    {
      path: '/success',
      name: 'success',
      component: () => import('../views/payments/Success.vue')
    },
    {
      path: '/smdlogin',
      name: 'SmdLogin',
      component: () => import('../views/auth/Login.vue')
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: () => import('../views/Dashboard.vue'),
    },
    {
      path: '/tableresponse',
      name: 'TableResponse',
      component: () => import('../views/TableResponse.vue'),
    },
    {
      path: '/posts',
      name: 'posts',
      component: () => import('../views/Posts.vue')
    },
    {
      path: '/page',
      name: 'page',
      component: () => import('../views/others/Landing.vue')
    },
    {
      path: '/email',
      name: 'email',
      component: () => import('../views/Email.vue')
    },
    {
      path: '/questionnaires',
      name: 'Questionnaires',
      component: () => import('../views/Questionnaires.vue')
    },
    {
      path: '/inf',
      name: 'INF',
      component: () => import('../views/others/Questionnaires.vue')
    },
    {
      path: '/quiz',
      name: 'Quiz',
      component: () => import('../views/others/Quiz.vue')
    },
    {
      path: '/formProcess',
      name: 'ProcessFrom',
      component: () => import('../views/others/ProcessForm.vue')
    },
    {
      path: '/calendar',
      name: 'Calendar',
      component: () => import('../views/others/Calendar.vue')
    },
    {
      path: '/post',
      name: 'Post',
      component: () => import('../views/others/Post.vue')
    },
    {
      path: '/blog',
      name: 'Blog',
      component: () => import('../views/others/Blog.vue')
    },
    //Maker Enviroment
    {
      path: '/smarpage',
      name: 'smarpage',
      component: () => import('../views/smartMaker/SmartPage.vue'),
    },
    {
      path: '/webpreview',
      name: 'webpreview',
      component: () => import('../views/WebSiteEdit.vue')
    },
    {
      path: '/smartmaker',
      name: 'Maker',
      component: () => import('../views/smartMaker/SmartMaker.vue'),
    },
    {
      path: '/makerpreview',
      name: 'makerpreview',
      component: () => import('../views/smartMaker/MakerPreview.vue')
    },
    //Apps Routes
    {
      path: '/login',
      name: 'login',
      component: () => import('../views/Apps/Auth/Login.vue')
    },
    //DLV
    {
      path: '/dlv-client',
      name: 'DLV-Client',
      component: () => import('../views/Apps/DLV/Client.vue'),
    },
    {
      path: '/dlv-driver',
      name: 'DLV-Driver',
      component: () => import('../views/Apps/DLV/Driver.vue'),
    },
    {
      path: '/dlv-pharmacy',
      name: 'DLV-Pharmacy',
      component: () => import('../views/Apps/DLV/Pharmacy.vue'),
    },
    //CLE
    {
      path: '/cle-client',
      name: 'CLE-Client',
      component: () => import('../views/Apps/CLE/Client.vue'),
    },
    {
      path: '/cle-Worker',
      name: 'CLE-Worker',
      component: () => import('../views/Apps/CLE/Worker.vue'),
    },
    //TRE
    {
      path: '/tre-client',
      name: 'TRE-Client',
      component: () => import('../views/Apps/TRE/Client.vue'),
    },
    {
      path: '/tre-Worker',
      name: 'TRE-Worker',
      component: () => import('../views/Apps/TRE/Worker.vue'),
    },
    //Dev enviroment
    {
      path: '/dev',
      name: 'dev',
      component: () => import('../views/Dev.vue')
    },
    
  ]
})
export default router
