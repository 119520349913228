/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const smd_listModules = /* GraphQL */ `
  query Smd_listModules {
    smd_listModules {
      data {
        PK
        SK
        GSP1PK1
        GSP1SK1
        GSP2PK1
        GSP2SK1
        GSP3PK1
        GSP3SK1
        GSP4PK1
        GSP4SK1
        entityType
        metaEntityType
        belongsToEntity
        createdAt
        updatedAt
        active
        createdBy
        fieldsMeta
        strAtt
      }
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_getDataSet = /* GraphQL */ `
  query Smd_getDataSet(
    $PK: String
    $SK: String
    $entityPK: String
    $entitySK: String
  ) {
    smd_getDataSet(PK: $PK, SK: $SK, entityPK: $entityPK, entitySK: $entitySK) {
      PK
      SK
      GSP1PK1
      GSP1SK1
      GSP2PK1
      GSP2SK1
      GSP3PK1
      GSP3SK1
      GSP4PK1
      GSP4SK1
      entity {
        PK
        SK
      }
      parent {
        PK
        SK
      }
      form {
        PK
        SK
      }
      isMeta
      createdAt
      updatedAt
      active
      createdBy
      dataObj
      attributes {
        attributeName
        attributeType
        inputType
        attributeValue
      }
      strAtt
      elements
      groupsCanRead
      groupsCanEdit
      usersCanEdit
      usersCanRead
      responsibles
      version
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
      delete_entityType
      readOnly
      friendlyId
      entityName
      shortEntity
    }
  }
`;
export const smd_getRootDataSet = /* GraphQL */ `
  query Smd_getRootDataSet($GSP1PK1: String, $GSP1SK1: String) {
    smd_getRootDataSet(GSP1PK1: $GSP1PK1, GSP1SK1: $GSP1SK1) {
      data
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
      entities
    }
  }
`;
export const smd_getRootPhonesEmails = /* GraphQL */ `
  query Smd_getRootPhonesEmails($GSP1PK1: String, $GSP1SK1: String) {
    smd_getRootPhonesEmails(GSP1PK1: $GSP1PK1, GSP1SK1: $GSP1SK1) {
      data
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
      entities
    }
  }
`;
export const smd_getMyOrganization = /* GraphQL */ `
  query Smd_getMyOrganization {
    smd_getMyOrganization {
      PK
      SK
      GSP1PK1
      GSP1SK1
      GSP2PK1
      GSP2SK1
      GSP3PK1
      GSP3SK1
      GSP4PK1
      GSP4SK1
      entity {
        PK
        SK
      }
      parent {
        PK
        SK
      }
      form {
        PK
        SK
      }
      isMeta
      createdAt
      updatedAt
      active
      createdBy
      dataObj
      attributes {
        attributeName
        attributeType
        inputType
        attributeValue
      }
      strAtt
      elements
      groupsCanRead
      groupsCanEdit
      usersCanEdit
      usersCanRead
      responsibles
      version
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
      delete_entityType
      readOnly
      friendlyId
      entityName
      shortEntity
    }
  }
`;
export const smd_getMyOrganizationByAlias = /* GraphQL */ `
  query Smd_getMyOrganizationByAlias($alias: String) {
    smd_getMyOrganizationByAlias(alias: $alias) {
      PK
      data
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_listEntityObj = /* GraphQL */ `
  query Smd_listEntityObj(
    $PK: String
    $shortEntity: String
    $active: String
    $pageSize: Int
    $index: String
    $group: String
  ) {
    smd_listEntityObj(
      PK: $PK
      shortEntity: $shortEntity
      active: $active
      pageSize: $pageSize
      index: $index
      group: $group
    ) {
      PK
      data
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_listEntity = /* GraphQL */ `
  query Smd_listEntity(
    $PK: String
    $shortEntity: String
    $active: String
    $pageSize: Int
  ) {
    smd_listEntity(
      PK: $PK
      shortEntity: $shortEntity
      active: $active
      pageSize: $pageSize
    ) {
      data {
        PK
        SK
        GSP1PK1
        GSP1SK1
        GSP2PK1
        GSP2SK1
        GSP3PK1
        GSP3SK1
        GSP4PK1
        GSP4SK1
        isMeta
        createdAt
        updatedAt
        active
        createdBy
        dataObj
        strAtt
        elements
        groupsCanRead
        groupsCanEdit
        usersCanEdit
        usersCanRead
        responsibles
        version
        delete_entityType
        readOnly
        friendlyId
        entityName
        shortEntity
      }
      lastEvaluatedKey {
        PK
        SK
      }
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_listMetaEntity = /* GraphQL */ `
  query Smd_listMetaEntity($PK: String, $shortEntity: String, $active: String) {
    smd_listMetaEntity(PK: $PK, shortEntity: $shortEntity, active: $active) {
      data {
        PK
        SK
        GSP1PK1
        GSP1SK1
        GSP2PK1
        GSP2SK1
        GSP3PK1
        GSP3SK1
        GSP4PK1
        GSP4SK1
        isMeta
        createdAt
        updatedAt
        active
        createdBy
        dataObj
        strAtt
        elements
        groupsCanRead
        groupsCanEdit
        usersCanEdit
        usersCanRead
        responsibles
        version
        delete_entityType
        readOnly
        friendlyId
        entityName
        shortEntity
      }
      lastEvaluatedKey {
        PK
        SK
      }
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_getPublicQuoteSet = /* GraphQL */ `
  query Smd_getPublicQuoteSet($quotePK: String, $quoteSK: String) {
    smd_getPublicQuoteSet(quotePK: $quotePK, quoteSK: $quoteSK) {
      data
      additionalData
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_getRecord = /* GraphQL */ `
  query Smd_getRecord($PK: String, $SK: String) {
    smd_getRecord(PK: $PK, SK: $SK) {
      PK
      SK
      GSP1PK1
      GSP1SK1
      GSP2PK1
      GSP2SK1
      GSP3PK1
      GSP3SK1
      GSP4PK1
      GSP4SK1
      entity {
        PK
        SK
      }
      parent {
        PK
        SK
      }
      form {
        PK
        SK
      }
      isMeta
      createdAt
      updatedAt
      active
      createdBy
      dataObj
      attributes {
        attributeName
        attributeType
        inputType
        attributeValue
      }
      strAtt
      elements
      groupsCanRead
      groupsCanEdit
      usersCanEdit
      usersCanRead
      responsibles
      version
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
      delete_entityType
      readOnly
      friendlyId
      entityName
      shortEntity
    }
  }
`;
export const smd_getRecordIndex = /* GraphQL */ `
  query Smd_getRecordIndex($PK: String, $SK: String, $index: String) {
    smd_getRecordIndex(PK: $PK, SK: $SK, index: $index) {
      data
      additionalData
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_searchContactByName = /* GraphQL */ `
  query Smd_searchContactByName($PK: String, $searchKey: String) {
    smd_searchContactByName(PK: $PK, searchKey: $searchKey) {
      data
      additionalData
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_getRecord2 = /* GraphQL */ `
  query Smd_getRecord2($PK: String, $SK: String) {
    smd_getRecord2(PK: $PK, SK: $SK) {
      PK
      data
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_getRecordObj = /* GraphQL */ `
  query Smd_getRecordObj($PK: String, $SK: String) {
    smd_getRecordObj(PK: $PK, SK: $SK) {
      PK
      data
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_getSmartLayoutByName = /* GraphQL */ `
  query Smd_getSmartLayoutByName($PK: String, $name: String) {
    smd_getSmartLayoutByName(PK: $PK, name: $name) {
      data
      additionalData
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_getSmartLayoutWithPermits = /* GraphQL */ `
  query Smd_getSmartLayoutWithPermits($PK: String, $SK: String) {
    smd_getSmartLayoutWithPermits(PK: $PK, SK: $SK) {
      PK
      SK
      GSP1PK1
      GSP1SK1
      GSP2PK1
      GSP2SK1
      GSP3PK1
      GSP3SK1
      GSP4PK1
      GSP4SK1
      entity {
        PK
        SK
      }
      parent {
        PK
        SK
      }
      form {
        PK
        SK
      }
      isMeta
      createdAt
      updatedAt
      active
      createdBy
      dataObj
      attributes {
        attributeName
        attributeType
        inputType
        attributeValue
      }
      strAtt
      elements
      groupsCanRead
      groupsCanEdit
      usersCanEdit
      usersCanRead
      responsibles
      version
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
      delete_entityType
      readOnly
      friendlyId
      entityName
      shortEntity
    }
  }
`;
export const smd_getSMLItem = /* GraphQL */ `
  query Smd_getSMLItem(
    $SMLPK: String
    $SMLSK: String
    $itemPK: String
    $itemSK: String
    $parent: smd_idInput
    $entity: smd_idInput
  ) {
    smd_getSMLItem(
      SMLPK: $SMLPK
      SMLSK: $SMLSK
      itemPK: $itemPK
      itemSK: $itemSK
      parent: $parent
      entity: $entity
    ) {
      data
      item
      layoutName
      layoutID
      layoutCategory
      ACLPermits {
        read
        write
      }
      SML
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_getCALItem = /* GraphQL */ `
  query Smd_getCALItem(
    $SMLPK: String
    $SMLSK: String
    $itemPK: String
    $itemSK: String
    $calendar: String
    $parent: smd_idInput
    $entity: smd_idInput
  ) {
    smd_getCALItem(
      SMLPK: $SMLPK
      SMLSK: $SMLSK
      itemPK: $itemPK
      itemSK: $itemSK
      calendar: $calendar
      parent: $parent
      entity: $entity
    ) {
      data
      item
      layoutName
      layoutID
      layoutCategory
      ACLPermits {
        read
        write
      }
      SML
      bookings
      availability
      duration
      calendarName
      calInstructions
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
      timeZone
      calendarAtts
    }
  }
`;
export const smd_getSMLItemByName = /* GraphQL */ `
  query Smd_getSMLItemByName(
    $SMLPK: String
    $layoutName: String
    $itemPK: String
    $itemSK: String
    $parent: smd_idInput
    $entity: smd_idInput
  ) {
    smd_getSMLItemByName(
      SMLPK: $SMLPK
      layoutName: $layoutName
      itemPK: $itemPK
      itemSK: $itemSK
      parent: $parent
      entity: $entity
    ) {
      data
      item
      layoutName
      layoutID
      layoutCategory
      ACLPermits {
        read
        write
      }
      SML
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_composeEmailTemplate = /* GraphQL */ `
  query Smd_composeEmailTemplate(
    $emailTPK: String
    $emailTSK: String
    $itemPK: String
    $baseURL: String
    $itemSK: String
    $itemGSP1PK1: String
    $itemGSP1SK1: String
  ) {
    smd_composeEmailTemplate(
      emailTPK: $emailTPK
      emailTSK: $emailTSK
      itemPK: $itemPK
      baseURL: $baseURL
      itemSK: $itemSK
      itemGSP1PK1: $itemGSP1PK1
      itemGSP1SK1: $itemGSP1SK1
    ) {
      data
      subject
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_getSMLMenu = /* GraphQL */ `
  query Smd_getSMLMenu($SMLPK: String, $SMLSK: String) {
    smd_getSMLMenu(SMLPK: $SMLPK, SMLSK: $SMLSK) {
      data
      item
      layoutName
      layoutID
      layoutCategory
      ACLPermits {
        read
        write
      }
      SML
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_getSMLMenu2 = /* GraphQL */ `
  query Smd_getSMLMenu2($SMLPK: String, $SMLSK: String) {
    smd_getSMLMenu2(SMLPK: $SMLPK, SMLSK: $SMLSK) {
      data
      item
      layoutName
      layoutID
      layoutCategory
      ACLPermits {
        read
        write
      }
      SML
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_listPAGWithData2 = /* GraphQL */ `
  query Smd_listPAGWithData2(
    $pagPK: String
    $pagSK: String
    $dataPK: String
    $active: String
    $searchKey: String
    $pageSize: Int
    $status: String
    $index: String
    $range: String
    $customRange: String
    $lastEvaluatedKey: inputLastEvaluatedKey
  ) {
    smd_listPAGWithData2(
      pagPK: $pagPK
      pagSK: $pagSK
      dataPK: $dataPK
      active: $active
      searchKey: $searchKey
      pageSize: $pageSize
      status: $status
      index: $index
      range: $range
      customRange: $customRange
      lastEvaluatedKey: $lastEvaluatedKey
    ) {
      SML
      header
      columnsFormat
      data
      status
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
      read
      edit
      entityInheritors {
        inheritorName
      }
      form {
        PK
        SK
      }
      view {
        PK
        SK
      }
      filter
      GSI4Label
      lastEvaluatedKey {
        PK
        SK
      }
    }
  }
`;
export const smd_getDashData = /* GraphQL */ `
  query Smd_getDashData(
    $SMLPK: String
    $SMLSK: String
    $GSP1PK1: String
    $ORGPK: String
    $rangeSelection: String
    $start: String
    $end: String
  ) {
    smd_getDashData(
      SMLPK: $SMLPK
      SMLSK: $SMLSK
      GSP1PK1: $GSP1PK1
      ORGPK: $ORGPK
      rangeSelection: $rangeSelection
      start: $start
      end: $end
    ) {
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
      data
    }
  }
`;
export const smd_getCustomerDash = /* GraphQL */ `
  query Smd_getCustomerDash($PK: String, $SK: String) {
    smd_getCustomerDash(PK: $PK, SK: $SK) {
      data
      additionalData
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_getWorkerDash = /* GraphQL */ `
  query Smd_getWorkerDash($PK: String, $SK: String) {
    smd_getWorkerDash(PK: $PK, SK: $SK) {
      data
      additionalData
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_getPharmacyDash = /* GraphQL */ `
  query Smd_getPharmacyDash($PK: String, $SK: String) {
    smd_getPharmacyDash(PK: $PK, SK: $SK) {
      data
      additionalData
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const listOrganizations = /* GraphQL */ `
  query ListOrganizations(
    $filter: TableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      GSP1PK1
      GSP1SK1
      GSP2PK1
      GSP2SK1
      GSP3PK1
      GSP3SK1
      GSP4PK1
      GSP4SK1
      PK
      SK
      id
      entityType
      createdAt
      updatedAt
      active
      createdBy
      smName
      smAddress
      l_productType
      l_acquisition
      l_taskStatusType
      l_paymentMethod
      l_discount
      l_team
      l_leadStatus
      l_quoteLibrary
      l_quoteStatus
      l_businessType
      q_counter
      i_counter
      funcRegion
      funcIdentityPoolId
      funcName
      funcSource
      searchText
      updateAt
    }
  }
`;
export const listAllOrganizations = /* GraphQL */ `
  query ListAllOrganizations {
    listAllOrganizations {
      GSP1PK1
      GSP1SK1
      GSP2PK1
      GSP2SK1
      GSP3PK1
      GSP3SK1
      GSP4PK1
      GSP4SK1
      PK
      SK
      id
      entityType
      createdAt
      updatedAt
      active
      createdBy
      smName
      smAddress
      l_productType
      l_acquisition
      l_taskStatusType
      l_paymentMethod
      l_discount
      l_team
      l_leadStatus
      l_quoteLibrary
      l_quoteStatus
      l_businessType
      q_counter
      i_counter
      funcRegion
      funcIdentityPoolId
      funcName
      funcSource
      searchText
      updateAt
    }
  }
`;
export const getOrganization = /* GraphQL */ `
  query GetOrganization($filter: TableFilterInput) {
    getOrganization(filter: $filter) {
      GSP1PK1
      GSP1SK1
      GSP2PK1
      GSP2SK1
      GSP3PK1
      GSP3SK1
      GSP4PK1
      GSP4SK1
      PK
      SK
      id
      entityType
      createdAt
      updatedAt
      active
      createdBy
      smName
      smAddress
      l_productType
      l_acquisition
      l_taskStatusType
      l_paymentMethod
      l_discount
      l_team
      l_leadStatus
      l_quoteLibrary
      l_quoteStatus
      l_businessType
      q_counter
      i_counter
      funcRegion
      funcIdentityPoolId
      funcName
      funcSource
      searchText
      updateAt
    }
  }
`;
export const smd_listAllMetaFields = /* GraphQL */ `
  query Smd_listAllMetaFields {
    smd_listAllMetaFields {
      data {
        PK
        SK
        GSP1PK1
        GSP1SK1
        GSP2PK1
        GSP2SK1
        GSP3PK1
        GSP3SK1
        GSP4PK1
        GSP4SK1
        isMeta
        createdAt
        updatedAt
        active
        createdBy
        dataObj
        strAtt
        elements
        groupsCanRead
        groupsCanEdit
        usersCanEdit
        usersCanRead
        responsibles
        version
        delete_entityType
        readOnly
        friendlyId
        entityName
        shortEntity
      }
      lastEvaluatedKey {
        PK
        SK
      }
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_listAllSmartLayouts = /* GraphQL */ `
  query Smd_listAllSmartLayouts {
    smd_listAllSmartLayouts {
      data {
        PK
        SK
        GSP1PK1
        GSP1SK1
        GSP2PK1
        GSP2SK1
        GSP3PK1
        GSP3SK1
        GSP4PK1
        GSP4SK1
        isMeta
        createdAt
        updatedAt
        active
        createdBy
        dataObj
        strAtt
        elements
        groupsCanRead
        groupsCanEdit
        usersCanEdit
        usersCanRead
        responsibles
        version
        delete_entityType
        readOnly
        friendlyId
        entityName
        shortEntity
      }
      lastEvaluatedKey {
        PK
        SK
      }
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_listAllMetaElements = /* GraphQL */ `
  query Smd_listAllMetaElements {
    smd_listAllMetaElements {
      data {
        PK
        SK
        GSP1PK1
        GSP1SK1
        GSP2PK1
        GSP2SK1
        GSP3PK1
        GSP3SK1
        GSP4PK1
        GSP4SK1
        isMeta
        createdAt
        updatedAt
        active
        createdBy
        dataObj
        strAtt
        elements
        groupsCanRead
        groupsCanEdit
        usersCanEdit
        usersCanRead
        responsibles
        version
        delete_entityType
        readOnly
        friendlyId
        entityName
        shortEntity
      }
      lastEvaluatedKey {
        PK
        SK
      }
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_listUserGroups = /* GraphQL */ `
  query Smd_listUserGroups {
    smd_listUserGroups {
      data
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_listUserGroups2 = /* GraphQL */ `
  query Smd_listUserGroups2 {
    smd_listUserGroups2 {
      data
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_listUsers = /* GraphQL */ `
  query Smd_listUsers {
    smd_listUsers {
      data
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_getUser = /* GraphQL */ `
  query Smd_getUser {
    smd_getUser {
      data
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_amzConnect = /* GraphQL */ `
  query Smd_amzConnect {
    smd_amzConnect {
      data
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const getCalendarByRange = /* GraphQL */ `
  query GetCalendarByRange(
    $filter: CalendarEntryByRangeInput
    $limit: Int
    $nextToken: String
  ) {
    getCalendarByRange(filter: $filter, limit: $limit, nextToken: $nextToken) {
      PK
      SK
      subject
      resource
      duration
      startDate
      createdAt
      updatedAt
      phone
      industry
      service
      updateAt
    }
  }
`;
export const smdUtil_devTools = /* GraphQL */ `
  query SmdUtil_devTools($operation: String, $params: String) {
    smdUtil_devTools(operation: $operation, params: $params)
  }
`;
export const smd_getRecordP = /* GraphQL */ `
  query Smd_getRecordP($PK: String, $SK: String) {
    smd_getRecordP(PK: $PK, SK: $SK) {
      data
      smd_responseP
    }
  }
`;
export const smd_getCart = /* GraphQL */ `
  query Smd_getCart($orgPK: String, $userSK: String, $parameters: String) {
    smd_getCart(orgPK: $orgPK, userSK: $userSK, parameters: $parameters) {
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
      data
    }
  }
`;
export const smd_contactOperations = /* GraphQL */ `
  query Smd_contactOperations($orgPK: String, $parameters: String) {
    smd_contactOperations(orgPK: $orgPK, parameters: $parameters) {
      data
      additionalData
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_getDashDataYear = /* GraphQL */ `
  query Smd_getDashDataYear($orgPK: String, $year: String) {
    smd_getDashDataYear(orgPK: $orgPK, year: $year) {
      data
      additionalData
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_getDashDataContact = /* GraphQL */ `
  query Smd_getDashDataContact(
    $GSP1PK1: String
    $ORGPK: String
    $active: String
  ) {
    smd_getDashDataContact(GSP1PK1: $GSP1PK1, ORGPK: $ORGPK, active: $active) {
      data
      additionalData
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_getDashDataContactExt = /* GraphQL */ `
  query Smd_getDashDataContactExt(
    $GSP1PK1: String
    $ORGPK: String
    $active: String
  ) {
    smd_getDashDataContactExt(
      GSP1PK1: $GSP1PK1
      ORGPK: $ORGPK
      active: $active
    ) {
      data
      additionalData
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_generateGraphicPost = /* GraphQL */ `
  query Smd_generateGraphicPost(
    $itemPK: String
    $itemSK: String
    $SMLPK: String
    $SMLSK: String
  ) {
    smd_generateGraphicPost(
      itemPK: $itemPK
      itemSK: $itemSK
      SMLPK: $SMLPK
      SMLSK: $SMLSK
    ) {
      data
      additionalData
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_getDocTemplatesList = /* GraphQL */ `
  query Smd_getDocTemplatesList($orgPK: String) {
    smd_getDocTemplatesList(orgPK: $orgPK) {
      data
      additionalData
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_getDocTemplateAttributes = /* GraphQL */ `
  query Smd_getDocTemplateAttributes(
    $orgPK: String
    $GSP1PK1: String
    $templateSK: String
  ) {
    smd_getDocTemplateAttributes(
      orgPK: $orgPK
      GSP1PK1: $GSP1PK1
      templateSK: $templateSK
    ) {
      data
      additionalData
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_generateDocTemplates = /* GraphQL */ `
  query Smd_generateDocTemplates(
    $orgPK: String
    $GSP1PK1: String
    $templateSK: String
    $attributes: String
  ) {
    smd_generateDocTemplates(
      orgPK: $orgPK
      GSP1PK1: $GSP1PK1
      templateSK: $templateSK
      attributes: $attributes
    ) {
      data
      additionalData
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const insuranceTitle_operations = /* GraphQL */ `
  query InsuranceTitle_operations(
    $orgPK: String
    $templateSK: String
    $item: String
    $operation: String
  ) {
    insuranceTitle_operations(
      orgPK: $orgPK
      templateSK: $templateSK
      item: $item
      operation: $operation
    ) {
      data
      additionalData
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_queryOperation = /* GraphQL */ `
  query Smd_queryOperation(
    $orgPK: String
    $templateSK: String
    $item: String
    $operation: String
  ) {
    smd_queryOperation(
      orgPK: $orgPK
      templateSK: $templateSK
      item: $item
      operation: $operation
    ) {
      data
      additionalData
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_getWebPage = /* GraphQL */ `
  query Smd_getWebPage(
    $orgPK: String
    $SMLPK: String
    $SMLSK: String
    $SMLname: String
  ) {
    smd_getWebPage(
      orgPK: $orgPK
      SMLPK: $SMLPK
      SMLSK: $SMLSK
      SMLname: $SMLname
    ) {
      data
      additionalData
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_getWebPage2 = /* GraphQL */ `
  query Smd_getWebPage2(
    $orgPK: String
    $SMLPK: String
    $SMLSK: String
    $alias: String
  ) {
    smd_getWebPage2(
      orgPK: $orgPK
      SMLPK: $SMLPK
      SMLSK: $SMLSK
      alias: $alias
    ) {
      data
      additionalData
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_publishWebPage = /* GraphQL */ `
  query Smd_publishWebPage($orgPK: String, $SMLPK: String, $SMLSK: String) {
    smd_publishWebPage(orgPK: $orgPK, SMLPK: $SMLPK, SMLSK: $SMLSK) {
      data
      additionalData
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_contactLensParser = /* GraphQL */ `
  query Smd_contactLensParser(
    $orgSK: String
    $bucket: String
    $fileKey: String
  ) {
    smd_contactLensParser(orgSK: $orgSK, bucket: $bucket, fileKey: $fileKey) {
      data
      additionalData
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_getSignedURL = /* GraphQL */ `
  query Smd_getSignedURL($orgSK: String, $fileKey: String) {
    smd_getSignedURL(orgSK: $orgSK, fileKey: $fileKey) {
      data
      additionalData
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_formProcess = /* GraphQL */ `
  query Smd_formProcess($itemPK: String, $itemSK: String, $action: String) {
    smd_formProcess(itemPK: $itemPK, itemSK: $itemSK, action: $action) {
      data
      additionalData
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_listBlogs = /* GraphQL */ `
  query Smd_listBlogs($PK: String) {
    smd_listBlogs(PK: $PK) {
      data
      additionalData
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_generateHTMLFile = /* GraphQL */ `
  query Smd_generateHTMLFile($itemPK: String, $itemSK: String) {
    smd_generateHTMLFile(itemPK: $itemPK, itemSK: $itemSK) {
      data
      additionalData
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_generateWebSite = /* GraphQL */ `
  query Smd_generateWebSite(
    $itemPK: String
    $itemSK: String
    $SMLname: String
    $HTML0: String
    $HTML1: String
    $HTML2: String
    $HTML3: String
    $palette: String
    $fonts: String
    $CSSStyles: String
    $destination: String
    $template: String
  ) {
    smd_generateWebSite(
      itemPK: $itemPK
      itemSK: $itemSK
      SMLname: $SMLname
      HTML0: $HTML0
      HTML1: $HTML1
      HTML2: $HTML2
      HTML3: $HTML3
      palette: $palette
      fonts: $fonts
      CSSStyles: $CSSStyles
      destination: $destination
      template: $template
    ) {
      data
      additionalData
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_applyCSS = /* GraphQL */ `
  query Smd_applyCSS(
    $itemPK: String
    $itemSK: String
    $CSSStyles: String
    $palette: String
    $fonts: String
  ) {
    smd_applyCSS(
      itemPK: $itemPK
      itemSK: $itemSK
      CSSStyles: $CSSStyles
      palette: $palette
      fonts: $fonts
    ) {
      data
      additionalData
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_translateHTML = /* GraphQL */ `
  query Smd_translateHTML($inputHTML: String, $language: String) {
    smd_translateHTML(inputHTML: $inputHTML, language: $language) {
      data
      additionalData
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_backendCustomQuery = /* GraphQL */ `
  query Smd_backendCustomQuery($items: String, $operation: String) {
    smd_backendCustomQuery(items: $items, operation: $operation) {
      data
      additionalData
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_getChildren = /* GraphQL */ `
  query Smd_getChildren($PK: String, $SK: String) {
    smd_getChildren(PK: $PK, SK: $SK) {
      data
      additionalData
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_getWebPageCode = /* GraphQL */ `
  query Smd_getWebPageCode($url: String) {
    smd_getWebPageCode(url: $url) {
      data
      additionalData
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_saasPaymentHistory = /* GraphQL */ `
  query Smd_saasPaymentHistory($orgPK: String) {
    smd_saasPaymentHistory(orgPK: $orgPK) {
      data
      additionalData
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const smd_paymentHistory = /* GraphQL */ `
  query Smd_paymentHistory($orgPK: String) {
    smd_paymentHistory(orgPK: $orgPK) {
      data
      additionalData
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
