/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onNotify = /* GraphQL */ `
  subscription OnNotify {
    onNotify {
      PK
      data
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const onUpdateAttributes = /* GraphQL */ `
  subscription OnUpdateAttributes {
    onUpdateAttributes {
      PK
      data
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const onsmd_attributesUpdate = /* GraphQL */ `
  subscription Onsmd_attributesUpdate {
    onsmd_attributesUpdate {
      PK
      SK
      GSP1PK1
      GSP1SK1
      GSP2PK1
      GSP2SK1
      GSP3PK1
      GSP3SK1
      GSP4PK1
      GSP4SK1
      entity {
        PK
        SK
      }
      parent {
        PK
        SK
      }
      form {
        PK
        SK
      }
      isMeta
      createdAt
      updatedAt
      active
      createdBy
      dataObj
      attributes {
        attributeName
        attributeType
        inputType
        attributeValue
      }
      strAtt
      elements
      groupsCanRead
      groupsCanEdit
      usersCanEdit
      usersCanRead
      responsibles
      version
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
      delete_entityType
      readOnly
      friendlyId
      entityName
      shortEntity
    }
  }
`;
export const onCloneItem = /* GraphQL */ `
  subscription OnCloneItem {
    onCloneItem {
      PK
      data
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const onUpdateSmartRecord = /* GraphQL */ `
  subscription OnUpdateSmartRecord($PK: String) {
    onUpdateSmartRecord(PK: $PK) {
      PK
      data
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const onsmd_processPayment = /* GraphQL */ `
  subscription Onsmd_processPayment {
    onsmd_processPayment {
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
      data
    }
  }
`;
export const onUpdateItem = /* GraphQL */ `
  subscription OnUpdateItem {
    onUpdateItem {
      PK
      data
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const onSetItemState = /* GraphQL */ `
  subscription OnSetItemState {
    onSetItemState {
      data
      additionalData
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
    }
  }
`;
export const onAddToCart = /* GraphQL */ `
  subscription OnAddToCart {
    onAddToCart {
      smd_response {
        statusCode
        message
        userMessage
        stack
        notifyme
        popAlert
      }
      data
    }
  }
`;
